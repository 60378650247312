import { CheckCircle } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Button as MuiButton,
  Popover,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { DatePicker, DateTimePicker, MonthCalendar } from '@mui/x-date-pickers';
import { differenceInDays, format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { ArrowLeft2, CloseCircle, Copy, CopySuccess, Edit, UserRemove, UserTick } from 'iconsax-react';
import { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  ConnectionStatus,
  EmailWhitelistEntry,
  Journal,
  Organization,
  OrganizationStatus,
  OrganizationType,
  PlaidLinkFailure,
  Province,
  Role,
  User,
  appEnv,
  useAdmin,
} from '../../../api';
import { Button, PageBody, PageContainer, PageHeader, ThreeColumn } from '../../../components';
import { getDateString, getFiscalYearEndForFY, getFiscalYearStartForFY } from '../../../utils/date-utils';
import { useFormState } from '../../../utils/useFormState';

const useAdminData = (selectedOrganization: Organization | null) => {
  const { fetchOrganizations, fetchJournals, fetchPlaidLinkSessionFailures, organizations, journals } = useAdmin();

  useEffect(() => {
    fetchOrganizations().catch((e) => {
      throw e;
    });
  }, [fetchOrganizations]);

  useEffect(() => {
    if (!selectedOrganization) {
      return;
    }

    fetchJournals(selectedOrganization.id!).catch((e) => {
      throw e;
    });

    fetchPlaidLinkSessionFailures(selectedOrganization.id!).catch((e) => {
      throw e;
    });
  }, [selectedOrganization, fetchJournals, fetchPlaidLinkSessionFailures]);

  const orgJournals = useMemo(() => {
    if (!selectedOrganization) {
      return null;
    }

    return journals[selectedOrganization.id!];
  }, [journals, selectedOrganization]);

  return {
    organizations,
    journals: orgJournals,
  };
};

function EditUserDialog({
  user,
  onClose,
  onUpdateUser,
}: {
  user: User | null;
  onClose: () => void;
  onUpdateUser: (changes: { id: string } & Partial<User>) => Promise<void>;
}) {
  const [legalName, setLegalName] = useState('');
  const [legalNameTouched, setLegalNameTouched] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [firstNameTouched, setFirstNameTouched] = useState(false);

  const [lastName, setLastName] = useState('');
  const [lastNameTouched, setLastNameTouched] = useState(false);

  const [mailingAddress, setMailingAddress] = useState('');
  const [mailingAddressTouched, setMailingAddressTouched] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberTouched, setPhoneNumberTouched] = useState(false);
  const phoneNumberError = useMemo(() => {
    return !/^\d{1,15}$/.test(phoneNumber);
  }, [phoneNumber]);

  const [emailIdentifier, setEmailIdentifier, emailIdentifierTouched, setEmailIdentifierTouched] = useFormState('');
  const emailIdentifierError = useMemo(() => {
    return !emailIdentifier || /[A-Z\s]/.test(emailIdentifier);
  }, [emailIdentifier]);

  const [timeZone, setTimeZone, timeZoneTouched, setTimeZoneTouched] = useFormState('');

  const error = useMemo(() => {
    return !firstName || !lastName || !legalName || !mailingAddress || phoneNumberError;
  }, [firstName, lastName, legalName, mailingAddress, phoneNumberError]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!user) {
      return;
    }

    setLegalName(user?.legalName || '');
    setLegalNameTouched(false);
    setFirstName(user?.firstName || '');
    setFirstNameTouched(false);
    setLastName(user?.lastName || '');
    setLastNameTouched(false);
    setMailingAddress(user?.mailingAddress || '');
    setMailingAddressTouched(false);
    setPhoneNumber(user?.phoneNumber ? user.phoneNumber.slice(1) : '');
    setPhoneNumberTouched(false);
    setTimeZone(user?.timeZone ? user.timeZone : '');
    setTimeZoneTouched(false);
    setEmailIdentifier(user?.emailIdentifier || '');
    setEmailIdentifierTouched(false);
  }, [user, setTimeZone, setEmailIdentifierTouched, setEmailIdentifier, setTimeZoneTouched]);

  const updateOwner = useCallback(async () => {
    if (error || !user) {
      return;
    }

    setLoading(true);
    try {
      const updates = { id: user.id! } as { id: string } & Partial<User>;

      if (firstNameTouched) {
        updates.firstName = firstName;
      }

      if (lastNameTouched) {
        updates.lastName = lastName;
      }

      if (legalNameTouched) {
        updates.legalName = legalName;
      }

      if (mailingAddressTouched) {
        updates.mailingAddress = mailingAddress;
      }

      if (phoneNumberTouched) {
        updates.phoneNumber = '+' + phoneNumber;
      }

      if (timeZoneTouched) {
        updates.timeZone = timeZone;
      }

      if (emailIdentifierTouched) {
        updates.emailIdentifier = emailIdentifier;
      }

      await onUpdateUser(updates);
    } finally {
      setLoading(false);
      onClose();
    }
  }, [
    onUpdateUser,
    firstName,
    firstNameTouched,
    lastName,
    lastNameTouched,
    legalName,
    legalNameTouched,
    mailingAddress,
    mailingAddressTouched,
    phoneNumber,
    phoneNumberTouched,
    timeZone,
    timeZoneTouched,
    error,
    user,
    emailIdentifier,
    emailIdentifierTouched,
    onClose,
  ]);

  return (
    <Dialog open={!!user} onClose={onClose} maxWidth='md' fullWidth>
      <DialogTitle>
        <ThreeColumn $mainColumn align='center'>
          <span></span>
          <span>Edit User</span>
          <IconButton onClick={onClose}>
            <CloseCircle />
          </IconButton>
        </ThreeColumn>
      </DialogTitle>
      <DialogContent>
        {user && (
          <Stack>
            <FormControl required disabled={true}>
              <TextField label='Email' placeholder='Email' value={user.email} />
            </FormControl>

            <FormControl required error={!legalName && legalNameTouched} disabled={loading}>
              <TextField
                label='Legal Name'
                placeholder='Name'
                value={legalName}
                onBlur={() => setLegalNameTouched(true)}
                onChange={(e) => setLegalName(e.target.value)}
              />
              {!legalName && legalNameTouched && <FormHelperText>Required</FormHelperText>}
            </FormControl>

            <FormControl required error={!firstName && firstNameTouched} disabled={loading}>
              <TextField
                label='Preferred first name'
                placeholder='Name'
                value={firstName}
                onBlur={() => setFirstNameTouched(true)}
                onChange={(e) => setFirstName(e.target.value)}
              />
              {!firstName && firstNameTouched && <FormHelperText>Required</FormHelperText>}
            </FormControl>

            <FormControl required error={!lastName && lastNameTouched} disabled={loading}>
              <TextField
                label='Preferred last name'
                placeholder='Name'
                value={lastName}
                onBlur={() => setLastNameTouched(true)}
                onChange={(e) => setLastName(e.target.value)}
              />
              {!lastName && lastNameTouched && <FormHelperText>Required</FormHelperText>}
            </FormControl>

            <FormControl required error={!mailingAddress && mailingAddressTouched} disabled={loading}>
              <TextField
                label='Mailing address'
                placeholder='Address'
                value={mailingAddress}
                onBlur={() => setMailingAddressTouched(true)}
                onChange={(e) => setMailingAddress(e.target.value)}
              />
              {!mailingAddress && mailingAddressTouched && <FormHelperText>Required</FormHelperText>}
            </FormControl>

            <FormControl required error={phoneNumberError && phoneNumberTouched} disabled={loading}>
              <TextField
                label='Phone number'
                placeholder='Phone number'
                value={phoneNumber}
                onBlur={() => setPhoneNumberTouched(true)}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              {phoneNumberError && phoneNumberTouched && <FormHelperText>Required</FormHelperText>}
            </FormControl>

            <Stack direction='row' alignItems='center'>
              <FormControl required error={emailIdentifierError && emailIdentifierTouched} disabled={loading} fullWidth>
                <TextField
                  label='Email Alias'
                  placeholder='Email alias...'
                  value={emailIdentifier}
                  onBlur={() => setEmailIdentifierTouched(true)}
                  onChange={(e) => setEmailIdentifier(e.target.value)}
                />
                {emailIdentifierError && emailIdentifierTouched && <FormHelperText>Required</FormHelperText>}
              </FormControl>

              <Typography flexShrink={0}>Reimbursement alias: {`reimburse+${emailIdentifier}@otter.ca`}</Typography>
            </Stack>

            <FormControl>
              <Autocomplete
                disablePortal
                disableClearable
                disabled={loading}
                options={['UTC', ...Intl.supportedValuesOf('timeZone')]}
                value={timeZone}
                onBlur={() => setTimeZoneTouched(true)}
                onChange={(_event, newValue) => {
                  setTimeZone(newValue);
                }}
                renderInput={(params) => <TextField {...params} label='Time Zone' />}
              />
            </FormControl>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='primary' onClick={updateOwner} disabled={loading || error}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface OrganizationsTableProps {
  organizations: Organization[];
  onEditOrganization: (organization: Organization) => void;
  style?: CSSProperties;
}
function OrganizationsTable({ organizations, onEditOrganization, style }: OrganizationsTableProps) {
  const theme = useTheme();
  const PAGE_SIZE = 50;

  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
      },
      {
        field: 'type',
        headerName: 'Type',
        renderCell: (params) => {
          const organization = params.row as Organization;

          switch (organization.type) {
            case OrganizationType.SOLO:
              return 'Solo';
            case OrganizationType.STARTUP:
              return 'Startup';
            default:
              return 'Unknown';
          }
        },
      },
      {
        field: 'fy',
        headerName: 'FY End',
        valueGetter: (params) => {
          const organization = params.row as Organization;

          const date = new Date(2020, organization.fyEndMonth - 1, 1);

          return format(date, 'MMMM');
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 200,
        renderCell: (params) => {
          const organization = params.row as Organization;

          return <Typography>{organization.status}</Typography>;
        },
      },
      {
        field: 'connectionStatus',
        headerName: 'Connection Status',
        minWidth: 100,
        maxWidth: 300,
        flex: 1,
        renderCell: (params) => {
          const organization = params.row as Organization;

          const isStale = organization.connectionStatus.status === ConnectionStatus.CONNECTION_STALE;
          const isFailed = organization.connectionStatus.status === ConnectionStatus.CONNECTION_ERROR;

          let label = 'OK';

          if (isFailed || isStale) {
            const ageInDays = differenceInDays(new Date(), organization.connectionStatus.connectionFailureDate!);
            let ageLabel;
            if (ageInDays === 0) {
              ageLabel = 'Today';
            } else if (ageInDays === 1) {
              ageLabel = 'Since Yesterday';
            } else {
              ageLabel = `${ageInDays} Days Ago`;
            }

            if (isStale) {
              label = `Connection Stale (${ageLabel})`;
            } else if (isFailed) {
              label = `Connection Error (${ageLabel})`;
            }

            return (
              <Box
                style={{
                  padding: theme.spacing(5),
                  backgroundColor: isFailed ? theme.palette.error.main : theme.palette.warning.main,
                }}
              >
                <Typography fontWeight={700} color={theme.palette.common.black}>
                  {label}
                </Typography>
              </Box>
            );
          } else {
            return (
              <Box
                style={{
                  padding: theme.spacing(5),
                  backgroundColor: theme.palette.success.main,
                }}
              >
                <Typography color={theme.palette.common.black} fontWeight={700}>
                  {label}
                </Typography>
              </Box>
            );
          }
        },
      },
      {
        field: 'edit',
        headerName: 'Edit',
        renderCell: (params) => {
          const organization = params.row as Organization;

          return (
            <Button variant='contained' color='primary' onClick={() => onEditOrganization(organization)}>
              Edit
            </Button>
          );
        },
      },
    ];
  }, [onEditOrganization, theme]);

  return (
    <DataGrid
      style={style}
      rows={organizations}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: PAGE_SIZE,
          },
        },
      }}
      pageSizeOptions={[PAGE_SIZE]}
    />
  );
}

function UserTable({
  users,
  onUpdateUser,
  style,
}: {
  users: User[] | null;
  onUpdateUser: (changes: { id: string } & Partial<User>) => Promise<void>;
  style?: CSSProperties;
}) {
  const PAGE_SIZE = 50;

  const theme = useTheme();

  const [idCopied, setIdCopied] = useState<string | null>(null);
  const copy = useCallback(async (documentId: string) => {
    setIdCopied(documentId);
    await navigator.clipboard.writeText(documentId);
  }, []);

  const [editUser, setEditUser] = useState<null | User>(null);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      renderCell: (params) => {
        const user = params.row as User;

        return (
          <Tooltip title={user.id}>
            <span>
              <IconButton onClick={() => copy(user.id!)}>
                {idCopied === user.id ? (
                  <CopySuccess size='1.1rem' variant='Bold' color={theme.palette.primary.main} />
                ) : (
                  <Copy size='1.1rem' variant='Outline' color={theme.palette.primary.main} />
                )}
              </IconButton>
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: 'firstName',
      headerName: 'First Name',
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'role',
      headerName: 'Role',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params) => {
        const user = params.row as User;

        let disableDisabledReason: string | null = null;
        if (user.role === Role.OWNER) {
          disableDisabledReason = 'cannot disable owner';
        } else if (user.role === Role.ADMIN) {
          disableDisabledReason = 'cannot disable admin';
        }

        return (
          <Stack direction='row' spacing={0}>
            <Tooltip title='Edit'>
              <span>
                <IconButton onClick={() => setEditUser(user)}>
                  <Edit variant='Bold' size='1rem' color={theme.palette.primary.main} />
                </IconButton>
              </span>
            </Tooltip>

            {user.isDisabled ? (
              <Tooltip title='Enable user'>
                <span>
                  <IconButton disabled={!user.isDisabled} onClick={() => onUpdateUser({ id: user.id!, isDisabled: false })}>
                    <UserTick variant='Bold' color={!user.isDisabled ? theme.palette.text.disabled : theme.palette.primary.main} size='1.1rem' />
                  </IconButton>
                </span>
              </Tooltip>
            ) : (
              <Tooltip title={disableDisabledReason ? `Disable user - ${disableDisabledReason}` : 'Disable user'}>
                <span>
                  <IconButton
                    disabled={user.role === Role.OWNER || user.role === Role.ADMIN}
                    onClick={() => onUpdateUser({ id: user.id!, isDisabled: true })}
                  >
                    <UserRemove
                      variant='Bold'
                      color={user.role === Role.OWNER || user.role === Role.ADMIN ? theme.palette.text.disabled : theme.palette.primary.main}
                      size='1.1rem'
                    />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </Stack>
        );
      },
    },
  ];

  return (
    <>
      <DataGrid
        style={style}
        loading={!users}
        rows={users || []}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: PAGE_SIZE,
            },
          },
        }}
        pageSizeOptions={[PAGE_SIZE]}
      />

      <EditUserDialog user={editUser} onClose={() => setEditUser(null)} onUpdateUser={onUpdateUser} />
    </>
  );
}

interface OrganizationsOverviewProps {
  organizations: Organization[];
  onEditOrganization: (organization: Organization) => void;
}
function OrganizationsOverview({ organizations, onEditOrganization }: OrganizationsOverviewProps) {
  return <OrganizationsTable organizations={organizations} onEditOrganization={onEditOrganization} />;
}

function getDateForMonth(monthNumber: number): Date | null {
  if (monthNumber < 1 || monthNumber > 12) {
    return null;
  }

  const currentYear = new Date().getFullYear();
  // Subtract 1 from monthNumber since the month parameter for the Date constructor is 0-indexed
  return new Date(currentYear, monthNumber - 1);
}

const Table = styled.table`
  border-collapse: separate;
  border-spacing: 1px;

  td,
  th {
    border: 0;
    background: ${({ theme }) => alpha(theme.palette.text.primary, 0.05)};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.palette.neutral[300]};
  }

  td {
    padding: ${({ theme }) => theme.spacing(2)};
  }

  th {
    padding: ${({ theme }) => theme.spacing(5)};
  }

  thead tr:first-child {
    th:first-child {
      border-top-left-radius: ${({ theme }) => theme.roundedCorners(2)};
    }

    th:last-child {
      border-top-right-radius: ${({ theme }) => theme.roundedCorners(2)};
    }
  }

  tbody tr:last-child {
    td:first-child {
      border-bottom-left-radius: ${({ theme }) => theme.roundedCorners(2)};
    }

    td:last-child {
      border-bottom-right-radius: ${({ theme }) => theme.roundedCorners(2)};
    }
  }
`;

enum IncomeType {
  SALES = 'Sales',
}

interface PlaidLinkSessionFailuresDialogProps {
  open: boolean;
  onClose: () => void;
  plaidLinkFailures: PlaidLinkFailure[];
}

function PlaidLinkSessionFailuresDialog({ open, onClose, plaidLinkFailures }: PlaidLinkSessionFailuresDialogProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Plaid Link Failures</DialogTitle>
      <DialogContent>
        <Table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Link Session ID</th>
            </tr>
          </thead>
          <tbody>
            {plaidLinkFailures.map((f) => (
              <tr key={f.id}>
                <td>{format(f.timestamp, 'MMM d, yyyy')}</td>
                <td>{f.linkSessionId}</td>
              </tr>
            ))}
            {plaidLinkFailures.length === 0 && (
              <tr>
                <td colSpan={2} style={{ textAlign: 'center' }}>
                  No data
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='neutral' onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface WhitelistConfigurationDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (whitelist: EmailWhitelistEntry[]) => void;
  emailWhitelist: EmailWhitelistEntry[];
}

function WhitelistConfigurationDialog({ emailWhitelist, open, onClose, onSave }: WhitelistConfigurationDialogProps) {
  const theme = useTheme();
  const [whitelist, setWhitelist] = useState(emailWhitelist);
  const [tempEntryType, setTempEntryType] = useState('ADDRESS');
  const [tempEntryValue, setTempEntryValue] = useState('');
  const [tempEntryValueTouched, setTempEntryValueTouched] = useState(false);

  useEffect(() => {
    setWhitelist(emailWhitelist);
  }, [emailWhitelist]);

  const removeEmailWhitelistEntry = (id: string | undefined) => {
    setWhitelist((existing) => {
      return existing.filter((e) => e.id !== id);
    });
  };

  const addEmailWhitelistEntry = () => {
    setWhitelist((existing) => {
      return [
        ...existing,
        {
          type: tempEntryType,
          value: tempEntryValue,
        },
      ];
    });

    setTempEntryType('ADDRESS');
    setTempEntryValue('');
    setTempEntryValueTouched(false);
  };

  const save = () => {
    onSave(whitelist);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Configure Email Whitelist</DialogTitle>
      <DialogContent>
        <Stack>
          <Table>
            <thead>
              <tr>
                <th>Type</th>
                <th>Value</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {whitelist.map((e) => (
                <tr key={`${e.type}-${e.value}`}>
                  <td>{e.type}</td>
                  <td>{e.value}</td>
                  <td align='center'>
                    <IconButton onClick={() => removeEmailWhitelistEntry(e.id)}>
                      <CloseCircle color={theme.palette.error.main} />
                    </IconButton>
                  </td>
                </tr>
              ))}
              {whitelist.length === 0 && (
                <tr>
                  <td colSpan={3} style={{ textAlign: 'center' }}>
                    No data
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          <Stack direction='row'>
            <Select
              label='Type'
              labelId='email-whitelist-entry-type'
              value={tempEntryType || 'ADDRESS'}
              onChange={(event) => setTempEntryType(event.target.value)}
              fullWidth
            >
              <MenuItem value='ADDRESS'>Address</MenuItem>
              <MenuItem value='DOMAIN'>Domain</MenuItem>
            </Select>

            <FormControl required error={!tempEntryValue && tempEntryValueTouched}>
              <TextField
                label='Value'
                placeholder={tempEntryType === 'ADDRESS' ? `E.g. john@gmail.com` : `E.g. @otter.ca`}
                value={tempEntryValue}
                onBlur={() => setTempEntryValueTouched(true)}
                onChange={(e) => setTempEntryValue(e.target.value)}
              />
              {!tempEntryValue && tempEntryValueTouched && <FormHelperText>Value required.</FormHelperText>}
            </FormControl>

            <IconButton disabled={!tempEntryValue} onClick={() => addEmailWhitelistEntry()}>
              <CheckCircle color={tempEntryValue ? 'primary' : 'disabled'} />
            </IconButton>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='neutral' onClick={onClose}>
          Close
        </Button>
        <Button variant='contained' color='primary' disabled={whitelist.length === 0} onClick={save}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface OrganizationDetailsProps {
  organization: Organization;
  journals: Journal[];
  onBack: () => void;
}
function OrganizationDetails({ organization, journals, onBack }: OrganizationDetailsProps) {
  const theme = useTheme();
  const {
    updateOrganizationDetails: apiUpdateOrganization,
    updateUser,
    fetchJournalAccounts,
    plaidLinkSessionFailures,
    fetchOrganizationUsers,
    users,
  } = useAdmin();

  useEffect(() => {
    fetchOrganizationUsers(organization.id!).catch((e) => {
      throw e;
    });
  }, [organization, fetchOrganizationUsers]);

  const organizationUsers = useMemo(() => {
    if (!users) {
      return null;
    }

    return users[organization.id!] || [];
  }, [users, organization]);

  const plaidLinkFailures = useMemo(() => {
    return plaidLinkSessionFailures[organization.id!] || [];
  }, [organization, plaidLinkSessionFailures]);

  const [legalBusinessName, setLegalBusinessName] = useState('');
  const [legalBusinessNameTouched, setLegalBusinessNameTouched] = useState(false);

  const [dba, setDba] = useState('');
  const [dbaTouched, setDbaTouched] = useState(false);

  const [description, setDescription] = useState('');
  const [descriptionTouched, setDescriptionTouched] = useState(false);

  const [businessNumber, setBusinessNumber] = useState('');
  const [businessNumberTouched, setBusinessNumberTouched] = useState(false);
  const businessNumberError = useMemo(() => {
    return !/^\d{9}$/.test(businessNumber);
  }, [businessNumber]);

  const [firstOtterManagedFy, setFirstOtterManagedFy] = useState('');
  const [firstOtterManagedFyTouched, setFirstOtterManagedFyTouched] = useState(false);
  const firstOtterManagedFyError = useMemo(() => {
    return !/^\d{4}$/.test(firstOtterManagedFy);
  }, [firstOtterManagedFy]);

  const [checkInScanStart, setCheckInScanStart, checkInScanStartTouched, setCheckInScanStartTouched] = useFormState<Date | null>(null);
  const [firstCheckInDate, setFirstCheckInDate, firstCheckInDateTouched, setFirstCheckInDateTouched] = useFormState<Date | null>(null);

  const [fyEndMonth, setFyEndMonth] = useState(12);
  const [fyEndMonthTouched, setFyEndMonthTouched] = useState(false);
  const [monthPickerOpen, setMonthPickerOpen] = useState(false);
  const fyEndMonthInputRef = useRef<HTMLInputElement | null>(null);

  const [defaultIncomeType, setDefaultIncomeType, defaultIncomeTypeTouched, setDefaultIncomeTypeTouched] = useFormState<string>(IncomeType.SALES);

  const [incorporationDate, setIncorporationDate] = useState<Date | null>(null);
  const [incorporationDateTouched, setIncorporationDateTouched] = useState(false);

  const [type, setType, typeTouched, setTypeTouched] = useFormState(organization.type);

  const [province, setProvince, provinceTouched, setProvinceTouched] = useFormState<Province | ''>('');

  const [status, setStatus, statusTouched, setStatusTouched] = useFormState(organization.status);

  const firstYearDateRange = useMemo(() => {
    if (!firstOtterManagedFy) {
      return null;
    }

    return {
      start: formatInTimeZone(getFiscalYearStartForFY(firstOtterManagedFy, fyEndMonth), 'UTC', 'MMM d, yyyy'),
      end: formatInTimeZone(getFiscalYearEndForFY(firstOtterManagedFy, fyEndMonth), 'UTC', 'MMM d, yyyy'),
    };
  }, [firstOtterManagedFy, fyEndMonth]);

  const firstYearJournal = useMemo(() => {
    return journals.find((j) => j.fy === firstOtterManagedFy);
  }, [journals, firstOtterManagedFy]);

  const [emailWhitelist, setEmailWhitelist] = useState<EmailWhitelistEntry[]>([]);

  useEffect(() => {
    if (!firstYearJournal) {
      return;
    }

    fetchJournalAccounts(firstYearJournal.id).catch((e) => {
      throw e;
    });
  }, [firstYearJournal, fetchJournalAccounts]);

  const [plaidLinkSessionFailuresDialogOpen, setPlaidLinkSessionFailuresDialogOpen] = useState(false);

  const [whitelistDialogOpen, setWhitelistDialogOpen] = useState(false);
  const [emailWhitelistTouched, setEmailWhitelistTouched] = useState(false);

  const formDocumentEmailAddress = (organization: Organization) => {
    if (appEnv === 'app') {
      if (organization.emailIdentifier) {
        return `documents+${organization.emailIdentifier}@otter.ca`;
      } else {
        return `documents+${organization.id!}@otter.ca`;
      }
    } else {
      if (organization.emailIdentifier) {
        return `documents-dev+${organization.emailIdentifier}@otter.ca`;
      } else {
        return `documents-dev+${organization.id!}@otter.ca`;
      }
    }
  };

  const [documentEmailAddress, setDocumentEmailAddress, documentEmailAddressTouched, setDocumentEmailAddressTouched] = useFormState(() =>
    formDocumentEmailAddress(organization)
  );
  const [documentEmailCoped, setDocumentEmailCopied] = useState(false);
  const copyDocumentEmail = useCallback(async () => {
    setDocumentEmailCopied(true);
    await navigator.clipboard.writeText(documentEmailAddress || '');
  }, [documentEmailAddress]);

  const organizationError = useMemo(() => {
    return (
      !legalBusinessName ||
      !dba ||
      businessNumberError ||
      !incorporationDate ||
      !description ||
      firstOtterManagedFyError ||
      fyEndMonth < 1 ||
      fyEndMonth > 12 ||
      !defaultIncomeType
    );
  }, [legalBusinessName, dba, businessNumberError, incorporationDate, description, firstOtterManagedFyError, fyEndMonth, defaultIncomeType]);

  const organizationDifferent = useMemo(() => {
    const expectedDocumentEmailAddress = formDocumentEmailAddress(organization);

    return (
      type !== organization.type ||
      legalBusinessName !== organization.legalName ||
      dba !== organization.name ||
      description !== organization.description ||
      businessNumber !== organization.businessNumber ||
      firstOtterManagedFy !== organization.firstOtterManagedFy ||
      fyEndMonth !== organization.fyEndMonth ||
      defaultIncomeType !== organization.defaultIncomeAccountType ||
      (incorporationDate && organization.incorporationDate && getDateString(incorporationDate) !== organization.incorporationDate) ||
      emailWhitelistTouched ||
      documentEmailAddress !== expectedDocumentEmailAddress ||
      status !== organization.status ||
      checkInScanStart !== organization.checkInScanStart ||
      firstCheckInDate !== organization.firstCheckInDate ||
      province !== organization.province
    );
  }, [
    organization,
    type,
    legalBusinessName,
    dba,
    description,
    businessNumber,
    firstOtterManagedFy,
    fyEndMonth,
    defaultIncomeType,
    incorporationDate,
    emailWhitelistTouched,
    documentEmailAddress,
    status,
    checkInScanStart,
    firstCheckInDate,
    province,
  ]);

  useEffect(() => {
    if (!organization) {
      return;
    }

    setType(organization.type);
    setLegalBusinessName(organization.legalName || '');
    setDba(organization.name || '');
    setDescription(organization.description || '');
    setBusinessNumber(organization.businessNumber || '');
    setFirstOtterManagedFy(organization.firstOtterManagedFy || '');
    setFyEndMonth(organization.fyEndMonth || 12);
    setDefaultIncomeType(organization.defaultIncomeAccountType || IncomeType.SALES);
    setIncorporationDate(organization.incorporationDate ? new Date(organization.incorporationDate) : null);
    setEmailWhitelist(organization.emailWhitelist || []);
    setStatus(organization.status);
    setCheckInScanStart(organization.checkInScanStart || null);
    setFirstCheckInDate(organization.firstCheckInDate || null);
    setProvince(organization.province || '');

    setDocumentEmailAddress(() => formDocumentEmailAddress(organization));
  }, [organization, setCheckInScanStart, setProvince, setFirstCheckInDate, setDefaultIncomeType, setType, setDocumentEmailAddress, setStatus]);

  const [orgLoading, setOrgLoading] = useState(false);

  const updateOrganization = useCallback(async () => {
    if (organizationError) {
      return;
    }

    const updates = {} as {
      name?: string;
      type?: OrganizationType;
      legalName?: string;
      businessNumber?: string;
      incorporationDate?: string;
      firstOtterManagedFy?: string;
      description?: string;
      fyEndMonth?: number;
      defaultIncomeAccountType?: string;
      status?: OrganizationStatus;
      emailWhitelist?: { type: string; value: string }[];
      documentEmailAddress?: string;
      checkInScanStart?: Date | null;
      firstCheckInDate?: Date | null;
      province?: Province | null;
    };

    if (typeTouched) {
      updates.type = type;
    }

    if (dbaTouched) {
      updates.name = dba;
    }

    if (legalBusinessNameTouched) {
      updates.legalName = legalBusinessName;
    }

    if (incorporationDateTouched) {
      updates.incorporationDate = getDateString(incorporationDate!);
    }

    if (descriptionTouched) {
      updates.description = description;
    }

    if (firstOtterManagedFyTouched) {
      updates.firstOtterManagedFy = firstOtterManagedFy;
    }

    if (fyEndMonthTouched) {
      updates.fyEndMonth = fyEndMonth;
    }

    if (defaultIncomeTypeTouched) {
      updates.defaultIncomeAccountType = defaultIncomeType;
    }

    if (emailWhitelistTouched) {
      updates.emailWhitelist = emailWhitelist;
    }

    if (documentEmailAddressTouched) {
      updates.documentEmailAddress = documentEmailAddress;
    }

    if (statusTouched) {
      updates.status = status;
    }

    if (checkInScanStartTouched) {
      updates.checkInScanStart = checkInScanStart;
    }

    if (firstCheckInDateTouched) {
      updates.firstCheckInDate = firstCheckInDate;
    }

    if (provinceTouched) {
      updates.province = province as Province;
    }

    setOrgLoading(true);
    try {
      await apiUpdateOrganization(organization.id!, updates);
    } finally {
      setOrgLoading(false);
    }
  }, [
    apiUpdateOrganization,
    organization,
    type,
    dba,
    legalBusinessName,
    incorporationDate,
    description,
    firstOtterManagedFy,
    fyEndMonth,
    defaultIncomeType,
    organizationError,
    emailWhitelist,
    status,
    documentEmailAddress,
    checkInScanStart,
    firstCheckInDate,
    province,
    checkInScanStartTouched,
    defaultIncomeTypeTouched,
    descriptionTouched,
    dbaTouched,
    documentEmailAddressTouched,
    emailWhitelistTouched,
    firstCheckInDateTouched,
    firstOtterManagedFyTouched,
    fyEndMonthTouched,
    incorporationDateTouched,
    legalBusinessNameTouched,
    provinceTouched,
    statusTouched,
    typeTouched,
  ]);

  return (
    <Stack>
      <MuiButton
        sx={{
          width: 'auto',
          alignSelf: 'start',
        }}
        variant='text'
        onClick={onBack}
      >
        <ArrowLeft2 variant='Bold' size='1rem' />
        Back
      </MuiButton>

      <Stack spacing={10}>
        <form>
          <Stack>
            <Stack>
              <Typography variant='h4'>Business</Typography>
              <FormControl required error={!legalBusinessName && legalBusinessNameTouched} disabled={orgLoading}>
                <TextField
                  label='Legal Business Name'
                  placeholder='Name'
                  value={legalBusinessName}
                  onBlur={() => setLegalBusinessNameTouched(true)}
                  onChange={(e) => setLegalBusinessName(e.target.value)}
                />
                {!legalBusinessName && legalBusinessNameTouched && <FormHelperText>Required</FormHelperText>}
              </FormControl>

              <FormControl required error={!dba && dbaTouched} disabled={orgLoading}>
                <TextField label='DBA' placeholder='Name' value={dba} onBlur={() => setDbaTouched(true)} onChange={(e) => setDba(e.target.value)} />
                {!dba && dbaTouched && <FormHelperText>Required</FormHelperText>}
              </FormControl>

              <FormControl required error={!description && descriptionTouched} disabled={orgLoading}>
                <TextField
                  label='Description'
                  placeholder='Description'
                  value={description}
                  onBlur={() => setDescriptionTouched(true)}
                  onChange={(e) => setDescription(e.target.value)}
                />
                {!description && descriptionTouched && <FormHelperText>Required</FormHelperText>}
              </FormControl>

              <FormControl required error={businessNumberError && businessNumberTouched} disabled={orgLoading}>
                <TextField
                  label='Business Number'
                  placeholder='#'
                  value={businessNumber}
                  onBlur={() => setBusinessNumberTouched(true)}
                  onChange={(e) => setBusinessNumber(e.target.value)}
                />
                {businessNumberError && businessNumberTouched && <FormHelperText>Invalid. A BN is a 9 digit number.</FormHelperText>}
              </FormControl>

              <FormControl disabled={orgLoading}>
                <InputLabel id='org-province-select-label'>Province</InputLabel>
                <Select
                  label='Province'
                  labelId='org-province-select-label'
                  value={province}
                  onChange={(event) => setProvince(event.target.value as Province)}
                  onBlur={() => setProvinceTouched(true)}
                >
                  {Object.values(Province).map((p) => {
                    return (
                      <MenuItem key={p} value={p}>
                        {p}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <Stack direction='row' alignItems='center'>
                <FormControl required error={firstOtterManagedFyError && firstOtterManagedFyTouched} disabled={orgLoading}>
                  <TextField
                    label='First Otter-Managed FY'
                    placeholder='YYYY'
                    value={firstOtterManagedFy}
                    onBlur={() => setFirstOtterManagedFyTouched(true)}
                    onChange={(e) => setFirstOtterManagedFy(e.target.value)}
                  />
                  {firstOtterManagedFyError && firstOtterManagedFyTouched && <FormHelperText>Invalid.</FormHelperText>}
                </FormControl>

                <Typography>First FY will cover {`${firstYearDateRange?.start || `Unknown`} - ${firstYearDateRange?.end || `Unknown`}`}</Typography>
              </Stack>

              <FormControl required error={!checkInScanStart && checkInScanStartTouched} disabled={orgLoading}>
                <DatePicker
                  label='Check-In Scan Start'
                  value={checkInScanStart}
                  onChange={(value) => {
                    setCheckInScanStart(value);
                    setCheckInScanStartTouched(true);
                  }}
                  onOpen={() => setCheckInScanStartTouched(true)}
                />
                {!checkInScanStart && checkInScanStartTouched && <FormHelperText>Invalid</FormHelperText>}
              </FormControl>

              <FormControl required error={!firstCheckInDate && firstCheckInDateTouched} disabled={orgLoading}>
                <DateTimePicker
                  label='First Check-In'
                  value={firstCheckInDate}
                  onChange={(value) => {
                    setFirstCheckInDate(value);
                    setFirstCheckInDateTouched(true);
                  }}
                  onOpen={() => setFirstCheckInDateTouched(true)}
                />
                {!firstCheckInDate && firstCheckInDateTouched && <FormHelperText>Invalid</FormHelperText>}
              </FormControl>

              <FormControl required error={(fyEndMonth < 1 || fyEndMonth > 12) && fyEndMonthTouched} disabled={orgLoading}>
                <TextField
                  label='FY End Month'
                  placeholder='Month'
                  value={fyEndMonth}
                  onClick={(e) => {
                    e.preventDefault();
                    setMonthPickerOpen(true);
                    setFyEndMonthTouched(true);
                  }}
                  inputRef={fyEndMonthInputRef}
                />
                {(fyEndMonth < 1 || fyEndMonth > 12) && fyEndMonthTouched && <FormHelperText>Invalid</FormHelperText>}
                <Popover
                  open={monthPickerOpen}
                  onClose={() => setMonthPickerOpen(false)}
                  anchorEl={fyEndMonthInputRef.current}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    horizontal: 'center',
                    vertical: 'top',
                  }}
                >
                  <MonthCalendar
                    value={getDateForMonth(fyEndMonth)}
                    onChange={(e: Date) => {
                      setFyEndMonth(e.getMonth() + 1);
                      setMonthPickerOpen(false);
                    }}
                  />
                </Popover>
              </FormControl>

              <FormControl disabled={orgLoading}>
                <InputLabel id='org-type-select-label'>Type</InputLabel>
                <Select
                  label='Type'
                  labelId='org-type-select-label'
                  value={type}
                  onChange={(event) => setType(event.target.value as OrganizationType)}
                  onBlur={() => setTypeTouched(true)}
                >
                  <MenuItem value={OrganizationType.SOLO}>Solo</MenuItem>
                  <MenuItem value={OrganizationType.STARTUP}>Startup</MenuItem>
                </Select>
              </FormControl>

              <FormControl disabled={orgLoading}>
                <InputLabel id='org-status-select-label'>Status</InputLabel>
                <Select
                  label='Status'
                  labelId='org-status-select-label'
                  value={status}
                  onChange={(event) => setStatus(event.target.value as OrganizationStatus)}
                  onBlur={() => setStatusTouched(true)}
                >
                  <MenuItem value={OrganizationStatus.ONBOARDED}>Onboarded</MenuItem>
                  <MenuItem value={OrganizationStatus.READY}>Ready</MenuItem>
                </Select>
              </FormControl>

              <Stack spacing={2}>
                <InputLabel>Plaid connection status</InputLabel>
                <Typography
                  color={
                    organization.connectionStatus.status === ConnectionStatus.CONNECTION_ERROR ? theme.palette.error.main : theme.palette.text.primary
                  }
                >
                  {organization.connectionStatus.status === ConnectionStatus.CONNECTION_ERROR ? 'Error' : 'Ok'}
                </Typography>
                <Button
                  variant='outlined'
                  color='primary'
                  sx={{
                    width: '180px',
                  }}
                  onClick={() => setPlaidLinkSessionFailuresDialogOpen(true)}
                >
                  See link session failures
                </Button>

                <PlaidLinkSessionFailuresDialog
                  open={plaidLinkSessionFailuresDialogOpen}
                  onClose={() => setPlaidLinkSessionFailuresDialogOpen(false)}
                  plaidLinkFailures={plaidLinkFailures}
                />
              </Stack>

              <FormControl required error={!incorporationDate && incorporationDateTouched} disabled={orgLoading}>
                <DatePicker
                  label='Incorporation Date'
                  value={incorporationDate}
                  onChange={(value) => {
                    setIncorporationDate(value);
                  }}
                  onOpen={() => setIncorporationDateTouched(true)}
                />
                {!incorporationDate && incorporationDateTouched && <FormHelperText>Invalid</FormHelperText>}
              </FormControl>

              <Stack spacing={2}>
                <InputLabel>Default income type</InputLabel>
                <ButtonGroup disabled={orgLoading}>
                  <MuiButton
                    variant={defaultIncomeType === IncomeType.SALES ? 'contained' : 'outlined'}
                    onClick={() => {
                      setDefaultIncomeType(IncomeType.SALES);
                      setDefaultIncomeTypeTouched(true);
                    }}
                  >
                    {IncomeType.SALES}
                  </MuiButton>
                </ButtonGroup>
              </Stack>

              <Stack spacing={2}>
                <InputLabel>Configure Email Whitelist</InputLabel>
                <Button
                  variant='outlined'
                  color='primary'
                  sx={{
                    width: '180px',
                  }}
                  onClick={() => setWhitelistDialogOpen(true)}
                >
                  Configure
                </Button>
                <FormHelperText>
                  Emails sent to documents@otter.ca will be processed for this organization if (and only if) they match the configured email
                  whitelist.
                </FormHelperText>

                <WhitelistConfigurationDialog
                  open={whitelistDialogOpen}
                  onClose={() => setWhitelistDialogOpen(false)}
                  emailWhitelist={emailWhitelist}
                  onSave={(whitelist) => {
                    setEmailWhitelist(whitelist);
                    setEmailWhitelistTouched(true);
                  }}
                />
              </Stack>

              <FormControl fullWidth>
                <TextField
                  label='Direct Document Email Address'
                  value={documentEmailAddress}
                  fullWidth
                  onChange={(e) => setDocumentEmailAddress(e.target.value)}
                  onBlur={() => setDocumentEmailAddressTouched(true)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton onClick={copyDocumentEmail} disabled={!documentEmailAddress}>
                          {documentEmailCoped ? (
                            <CopySuccess size='1.1rem' variant='Bold' color={theme.palette.primary.main} />
                          ) : (
                            <Copy size='1.1rem' variant='Outline' color={theme.palette.primary.main} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <FormHelperText>
                  Any document emails sent to this address will be specifically processed for this organization, regardless of whitelist
                  configuration.
                </FormHelperText>
              </FormControl>
            </Stack>

            <Button
              variant='contained'
              color='primary'
              onClick={updateOrganization}
              disabled={orgLoading || organizationError || !organizationDifferent}
            >
              Save
            </Button>
          </Stack>
        </form>

        <Divider />

        <form>
          <Stack>
            <Typography variant='h4'>Users</Typography>

            <UserTable users={organizationUsers} onUpdateUser={(changes) => updateUser(organization.id!, changes.id, changes)} />
          </Stack>
        </form>
      </Stack>
    </Stack>
  );
}

enum View {
  ORGANIZATIONS_OVERVIEW = 'ORGANIZATIONS_OVERVIEW',
  ORGANIZATION_DETAILS = 'ORGANIZATION_DETAILS',
}

export function AdminOrganizationsPage({ ...props }) {
  const [selectedOrganization, setSelectedOrganization] = useState<Organization | null>(null);
  const [view, setView] = useState(View.ORGANIZATIONS_OVERVIEW);
  const { organizations, journals } = useAdminData(selectedOrganization);

  useEffect(() => {
    if (!organizations) {
      return;
    }

    setSelectedOrganization((selected) => {
      if (!selected) {
        return selected;
      }

      const org = organizations.find((o) => selected.id === o.id)!;

      return org;
    });
  }, [organizations]);

  const viewOrganization = useCallback((organization: Organization | null) => {
    setSelectedOrganization(organization);
    if (organization) {
      setView(View.ORGANIZATION_DETAILS);
    } else {
      setView(View.ORGANIZATIONS_OVERVIEW);
    }
  }, []);

  if (!organizations) {
    return (
      <PageContainer {...props}>
        <PageHeader title='Admin - Journals' />
        <PageBody gutter='thin' style={{ alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </PageBody>
      </PageContainer>
    );
  }

  return (
    <PageContainer {...props}>
      <PageHeader title='Admin - Organizations' />
      <PageBody gutter='thin'>
        <Stack height='100%'>
          {view === View.ORGANIZATIONS_OVERVIEW && (
            <OrganizationsOverview organizations={organizations} onEditOrganization={(organization) => viewOrganization(organization)} />
          )}
          {selectedOrganization && journals && view === View.ORGANIZATION_DETAILS && (
            <OrganizationDetails organization={selectedOrganization} journals={journals} onBack={() => viewOrganization(null)} />
          )}
        </Stack>
      </PageBody>
    </PageContainer>
  );
}
