import { CheckCircle } from '@mui/icons-material';
import {
  alpha,
  Box,
  Chip,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fade,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { Add, ArrowRight, Clock, CloseCircle, TickCircle, Warning2 } from 'iconsax-react';
import { CSSProperties, forwardRef, KeyboardEvent, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import {
  Account,
  deserializeDocument,
  Document,
  documentTotal,
  DocumentType,
  DocumentUnmatchedReason,
  Organization,
  OrganizationType,
  Role,
  SerializedDocument,
  StandardAccounts,
  useConversation,
  useDocument,
  useLedger,
  useOrganization,
  User,
  useSession,
} from '../../../api';
import { useCurrentConversation } from '../../../pages/user/current-conversation-context';
import { SMALL_HORIZONTAL_SPACING, SMALL_VERTICAL_SPACING } from '../../../theme';
import { getFiscalYear } from '../../../utils/date-utils';
import { ScreenSize, useScreenSize } from '../../../utils/use-screen-size';
import { useFormState } from '../../../utils/useFormState';
import { DelayCollapse, DelayFade } from '../../animations';
import { Button } from '../../button';
import { ConfirmDialog } from '../../confirm-dialog';
import { DocumentImg, DocumentViewer } from '../../document-img';
import { InviteUserDialog } from '../../invite-user-dialog';
import { OutlineContainer, OutlineContainerSection, useOutlineContainerBorder } from '../../outline-container';
import { ProgressBar } from '../../small-progress-bar';
import { ThreeColumn } from '../../three-column';
import { IWidgetProps } from '../IWidgetProps';
import { AccountDetailsDialog, restrictLength } from './clarification';

interface DocumentClarificationCompleteContentProps {
  loading: boolean;
  error?: string;
  savedForLater?: boolean;
}
function DocumentClarificationCompleteContent({ loading, error, savedForLater }: DocumentClarificationCompleteContentProps) {
  const theme = useTheme();

  let content: React.ReactNode;
  if (savedForLater) {
    content = (
      <Stack direction='row' alignItems='center' spacing={1}>
        <Clock size='1.5rem' color={theme.palette.warning.main} />
        <Typography>You told me you would handle this later</Typography>
      </Stack>
    );
  } else {
    content = (
      <Stack direction='row' alignItems='center' spacing={1}>
        <TickCircle size='1.5rem' color={theme.palette.primary.main} />
        <Typography>This one's done!</Typography>
      </Stack>
    );
  }

  return (
    <Stack direction='row' alignItems='center' justifyContent='space-between' width='100%'>
      {content}
      {loading && <CircularProgress size='1rem' color='primary' />}
      {error && (
        <Tooltip title={error}>
          <Warning2 color={theme.palette.error.main} size='1.5rem' />
        </Tooltip>
      )}
    </Stack>
  );
}

const useConversationData = () => {
  const { data } = useCurrentConversation();
  const { organization } = useSession();
  const { fetchAccounts, fetchJournals, journals, accounts } = useLedger();

  useEffect(() => {
    fetchJournals().catch((e) => {
      throw e;
    });
  }, [fetchJournals]);

  const formattedData = useMemo<null | {
    documentsCompleted: string[];
    unmatchedDocuments: Document[];
    documentsRequiringDescriptions: Document[];
  }>(() => {
    if (data === null) {
      return null;
    }

    const castedData = data as {
      documentsCompleted: string[];
      unmatchedDocuments: SerializedDocument[];
      documentsRequiringDescriptions: SerializedDocument[];
    };

    return {
      ...castedData,
      unmatchedDocuments: castedData.unmatchedDocuments.map(deserializeDocument),
      documentsRequiringDescriptions: castedData.documentsRequiringDescriptions.map(deserializeDocument),
    };
  }, [data]);

  useEffect(() => {
    if (!formattedData || !journals || !organization) {
      return;
    }

    const fys = new Set<string>();
    for (const doc of formattedData.unmatchedDocuments) {
      if (doc.date) {
        fys.add(getFiscalYear(doc.date, organization.fyEndMonth));
      }
    }

    for (const fy of fys) {
      const journal = journals.find((j) => j.fy === fy);
      if (!journal) {
        continue;
      }

      fetchAccounts(journal.id).catch((e) => {
        throw e;
      });
    }
  }, [journals, formattedData, organization, fetchAccounts]);

  const conversationData = useMemo<null | {
    documentsCompleted: string[];
    unmatchedDocuments: (Document & { account: Account | null; possiblePaymentAccounts: Account[] })[];
    documentsRequiringDescriptions: (Document & { account: Account | null })[];
  }>(() => {
    if (!formattedData || !organization || !journals) {
      return null;
    }

    const getAccount = (document: Document) => {
      if (!document.paymentDate && !document.date) {
        return null;
      }

      const documentPaymentDate = document.paymentDate ? document.paymentDate : document.date!;
      const fy = getFiscalYear(documentPaymentDate, organization.fyEndMonth);

      const journal = journals.find((j) => j.fy === fy);
      if (!journal) {
        return null;
      }

      const fyAccounts = accounts[journal.id];
      if (!fyAccounts) {
        return null;
      }

      let matches: Account[];
      if (document.paymentAccountNumber && document.paymentCardType) {
        matches = fyAccounts.filter(
          (a) =>
            a.externalMaskedAccountId &&
            document.paymentAccountNumber!.includes(a.externalMaskedAccountId) &&
            (!a.externalType || a.externalType === document.paymentCardType)
        );
      } else if (document.paymentAccountNumber) {
        matches = fyAccounts.filter((a) => a.externalMaskedAccountId && document.paymentAccountNumber!.includes(a.externalMaskedAccountId));
      } else if (document.paymentCardType) {
        matches = fyAccounts.filter((a) => document.paymentCardType === a.externalType);
      } else {
        return null;
      }

      if (matches.length !== 1) {
        return null;
      }

      return matches[0];
    };

    const getPossiblePaymentAccounts = (document: Document) => {
      if (!document.paymentDate && !document.date) {
        return [];
      }

      const documentPaymentDate = document.paymentDate ? document.paymentDate : document.date!;
      const fy = getFiscalYear(documentPaymentDate, organization.fyEndMonth);

      const journal = journals.find((j) => j.fy === fy);
      if (!journal) {
        return [];
      }

      const fyAccounts = accounts[journal.id];
      if (!fyAccounts) {
        return [];
      }

      return fyAccounts.filter(
        (a) =>
          a.standardAccount === StandardAccounts.CASH ||
          a.standardAccount === StandardAccounts.PERSONAL_AND_CREDIT_CARD_LOANS ||
          a.standardAccount === StandardAccounts.LINE_OF_CREDIT
      );
    };

    const unmatchedDocuments: (Document & { account: Account | null; possiblePaymentAccounts: Account[] })[] = [];
    for (const doc of formattedData.unmatchedDocuments) {
      const account = getAccount(doc);
      const possiblePaymentAccounts = getPossiblePaymentAccounts(doc);

      unmatchedDocuments.push({
        ...doc,
        account,
        possiblePaymentAccounts,
      });
    }

    const documentsRequiringDescriptions: (Document & { account: Account | null; possiblePaymentAccounts?: Account[] })[] = [];
    for (const doc of formattedData.documentsRequiringDescriptions) {
      const account = getAccount(doc);
      const possiblePaymentAccounts = getPossiblePaymentAccounts(doc);

      documentsRequiringDescriptions.push({
        ...doc,
        account,
        possiblePaymentAccounts,
      });
    }

    return {
      documentsCompleted: formattedData.documentsCompleted,
      unmatchedDocuments,
      documentsRequiringDescriptions,
    };
  }, [formattedData, accounts, organization, journals]);

  return conversationData;
};

const DocumentPlaceholder = forwardRef<HTMLDivElement>(({ style }: { style?: CSSProperties }, ref) => {
  const outlineContainerBorder = useOutlineContainerBorder();
  const randomSeed = useRef(Math.random());
  const theme = useTheme();

  return (
    <OutlineContainer ref={ref} background={theme.palette.background.default} style={style}>
      <OutlineContainerSection borderBottom={outlineContainerBorder} paddingX={SMALL_HORIZONTAL_SPACING} paddingY={SMALL_VERTICAL_SPACING}>
        <Skeleton variant='rounded' width={32} height={32} />

        <Stack spacing={0} flex={1}>
          <Skeleton variant='text' width={128 + randomSeed.current * 128} />
          <Skeleton variant='text' width={128 + randomSeed.current * 256} />
        </Stack>

        <Stack spacing={0}>
          <Skeleton variant='text' width={64} />
          <Skeleton variant='text' width={64} />
        </Stack>
      </OutlineContainerSection>

      <OutlineContainerSection paddingX={SMALL_HORIZONTAL_SPACING} paddingY={SMALL_VERTICAL_SPACING}>
        <Skeleton width={64} />
        <Skeleton width={96} />
      </OutlineContainerSection>
    </OutlineContainer>
  );
});

function DocumentImageDialog({ openFor, onClose }: { openFor: Document | null; onClose: () => void }) {
  const theme = useTheme();

  const date = openFor?.paymentDate || openFor?.date;
  const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: openFor?.currency || 'CAD' });

  let amount: string;
  if (!openFor?.afterTax) {
    amount = 'Unknown';
  } else if (openFor.discount) {
    amount = `${amountFormatter.format(documentTotal(openFor))} (discounted from ${amountFormatter.format(parseFloat(openFor.afterTax))})`;
  } else {
    amount = amountFormatter.format(parseFloat(openFor.afterTax));
  }

  return (
    <Dialog open={!!openFor} onClose={onClose} style={{ margin: theme.spacing(SMALL_HORIZONTAL_SPACING) }} fullScreen>
      <DialogTitle>
        <ThreeColumn $mainColumn align='center'>
          <span></span>
          <span>
            {openFor?.merchantName
              ? `Document - ${openFor.merchantName}`
              : `Document - Unknown ${openFor?.type === DocumentType.INVOICE ? 'Invoice' : 'Receipt'}`}
          </span>
          <IconButton onClick={onClose}>
            <CloseCircle />
          </IconButton>
        </ThreeColumn>
      </DialogTitle>
      <DialogContent>
        {openFor && (
          <Stack direction='row' height='100%'>
            <Stack minWidth={theme.spacing(64)}>
              <Typography variant='h3'>Document Information</Typography>

              <Box>
                <Typography variant='h5'>Type</Typography>
                <Chip
                  size='small'
                  sx={{
                    fontSize: '0.666rem',
                  }}
                  color={openFor.isIncome ? 'primary' : 'error'}
                  label={openFor.isIncome ? 'INCOME' : 'EXPENSE'}
                />
              </Box>

              <Box>
                <Typography variant='h5'>Received by Otter</Typography>
                <Typography>{format(openFor.created, 'MMM d, yyyy') || 'Unknown'}</Typography>
              </Box>

              <Box>
                <Typography variant='h5'>Merchant</Typography>
                <Typography>{openFor.merchantName || 'Unknown'}</Typography>
              </Box>

              <Box>
                <Typography variant='h5'>Document Date</Typography>
                <Typography>{date ? format(date, 'MMM d, yyyy') : 'Unknown'}</Typography>
              </Box>

              <Box>
                <Typography variant='h5'>Amount (after tax)</Typography>
                <Typography>{amount}</Typography>
              </Box>

              {openFor.GST && (
                <Box>
                  <Typography variant='h5'>GST</Typography>
                  <Typography>{amountFormatter.format(parseFloat(openFor.GST))}</Typography>
                </Box>
              )}

              {openFor.HST && (
                <Box>
                  <Typography variant='h5'>HST</Typography>
                  <Typography>{amountFormatter.format(parseFloat(openFor.HST))}</Typography>
                </Box>
              )}

              {openFor.PST && (
                <Box>
                  <Typography variant='h5'>PST</Typography>
                  <Typography>{amountFormatter.format(parseFloat(openFor.PST))}</Typography>
                </Box>
              )}
            </Stack>

            <Box overflow='auto' style={{ flex: 1 }}>
              <DocumentViewer document={openFor} style={{ width: '100%', height: '100%' }} />
            </Box>
          </Stack>
        )}
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
}

interface DocumentUnmatchedClarificationContentProps {
  document: Document & { possiblePaymentAccounts?: Account[] };
  organization: Organization;
  orgUsers: User[];
  onUnmatchedReasonProvided: (unmatchedReason: DocumentUnmatchedReason) => void;
  onPaymentAccountSpecified: (paymentAccount: string) => void;
  onReimburseToUserIdSpecified: (reimburseToUserId: string | null) => void;
  onInviteUser: (email: string, role: Role) => Promise<void>;
  style?: CSSProperties;
}
const DocumentUnmatchedClarificationContent = forwardRef<HTMLInputElement, DocumentUnmatchedClarificationContentProps>(
  (
    { document, organization, orgUsers, onUnmatchedReasonProvided, onPaymentAccountSpecified, onReimburseToUserIdSpecified, onInviteUser, style },
    ref
  ) => {
    const theme = useTheme();

    const [showDocumentImageDialog, setShowDocumentImageDialog] = useState(false);
    const [unmatchedReason, setUnmatchedReason] = useState<DocumentUnmatchedReason | ''>('');
    const [paymentAccount, setPaymentAccount] = useState<string | null>(null);
    const [reimburseToUserId, setReimburseToUserId] = useState<string | null>(null);
    const [reimburseToUserIdTouched, setReimburseToUserIdTouched] = useState(false);
    const reimburseToUserIdError = useMemo(() => {
      return unmatchedReason === DocumentUnmatchedReason.REIMBURSEMENT && organization.hasPayroll && !reimburseToUserId;
    }, [unmatchedReason, reimburseToUserId, organization]);
    const [inviteUserDialogOpen, setInviteUserDialogOpen] = useState(false);
    const [queuedUserInvite, setQueuedUserInvite] = useState<{
      email: string;
      role: Role;
    } | null>(null);
    const screenSize = useScreenSize();

    const paymentAccountInitLock = useRef<DocumentUnmatchedReason | null>(null);
    useEffect(() => {
      if (
        unmatchedReason === DocumentUnmatchedReason.PAYMENT_ACCOUNT_NUMBER &&
        paymentAccountInitLock.current !== DocumentUnmatchedReason.PAYMENT_ACCOUNT_NUMBER
      ) {
        if (!document.possiblePaymentAccounts) {
          throw new Error(`No possible payment accounts for ${document.id}`);
        }

        paymentAccountInitLock.current = unmatchedReason;

        const accountId = document.possiblePaymentAccounts[0].id;
        setPaymentAccount(accountId);
        onPaymentAccountSpecified(accountId);
      }
    }, [unmatchedReason, document, onPaymentAccountSpecified]);

    const changeUnmatchedReason = (unmatchedReason: DocumentUnmatchedReason) => {
      setUnmatchedReason(unmatchedReason);
      onUnmatchedReasonProvided(unmatchedReason);
    };

    const changePaymentAccount = (accountId: string) => {
      setPaymentAccount(accountId);
      onPaymentAccountSpecified(accountId);
    };

    const changeReimburseToUserId = (userId: string) => {
      if (userId === 'none') {
        setReimburseToUserId(null);
        onReimburseToUserIdSpecified(null);
      } else {
        setReimburseToUserId(userId);
        onReimburseToUserIdSpecified(userId);
      }
    };

    const confirmQueuedUserInvite = async () => {
      if (!queuedUserInvite) {
        return;
      }

      await onInviteUser(queuedUserInvite.email, queuedUserInvite.role);
      setQueuedUserInvite(null);
    };

    if (!document.possiblePaymentAccounts) {
      throw new Error(`No possible payment accounts for ${document.id}`);
    }

    return (
      <OutlineContainerSection
        paddingX={SMALL_HORIZONTAL_SPACING}
        paddingY={SMALL_VERTICAL_SPACING}
        spacing={SMALL_VERTICAL_SPACING}
        direction='row'
        justifyContent='space-between'
        style={{
          ...style,
          flexDirection: screenSize !== ScreenSize.LARGE ? 'column' : 'row',
        }}
        ref={ref}
      >
        <Stack style={{ flex: 1, minWidth: 0 }}>
          <Typography>Please select the option that best describes this expense:</Typography>
          <Stack>
            <FormControl fullWidth>
              <InputLabel id='unmatched-reason-label' shrink={!!unmatchedReason}>
                Description
              </InputLabel>
              <Select
                labelId='unmatched-reason-label'
                label='Description'
                notched={!!unmatchedReason}
                value={unmatchedReason}
                onChange={(event) => changeUnmatchedReason(event.target.value as DocumentUnmatchedReason)}
                style={{ flex: 1, minWidth: 100 }}
              >
                <MenuItem value={DocumentUnmatchedReason.PERSONAL_EXPENSE}>It's a personal expense (we'll set it aside and ignore it)</MenuItem>
                <MenuItem value={DocumentUnmatchedReason.REIMBURSEMENT}>
                  It's a business expense paid for with personal funds (we'll mark it for reimbursement)
                </MenuItem>
                <MenuItem value={DocumentUnmatchedReason.PAYMENT_ACCOUNT_NUMBER}>
                  It's a business expense paid for with a business account or card
                </MenuItem>
                <Divider />
                <MenuItem value={DocumentUnmatchedReason.UNKNOWN}>I don't know</MenuItem>
              </Select>
            </FormControl>

            {unmatchedReason === DocumentUnmatchedReason.PAYMENT_ACCOUNT_NUMBER && (
              <Box display='flex'>
                <Collapse in={true} timeout={300} mountOnEnter unmountOnExit style={{ flex: 1, minWidth: 100 }}>
                  <FormControl fullWidth>
                    <InputLabel id='payment-account-label' shrink={!!paymentAccount}>
                      Payment Account
                    </InputLabel>
                    <Select
                      label='Payment Account'
                      labelId='payment-account-label'
                      notched={!!paymentAccount}
                      value={paymentAccount}
                      onChange={(e) => changePaymentAccount(e.target.value!)}
                    >
                      {document.possiblePaymentAccounts.map((a) => (
                        <MenuItem key={a.id} value={a.id}>
                          {a.name}
                        </MenuItem>
                      ))}
                      <Divider />
                      <MenuItem value={`I don't know`}>I don't know</MenuItem>
                    </Select>
                  </FormControl>
                </Collapse>
              </Box>
            )}

            {organization.type === OrganizationType.STARTUP &&
              organization.hasPayroll &&
              unmatchedReason === DocumentUnmatchedReason.REIMBURSEMENT && (
                <>
                  <Box display='flex'>
                    <Collapse in={true} timeout={300} mountOnEnter unmountOnExit style={{ flex: 1, minWidth: 100 }}>
                      <FormControl fullWidth error={reimburseToUserIdTouched && reimburseToUserIdError && !inviteUserDialogOpen && !queuedUserInvite}>
                        <InputLabel id='reimburse-to-label' shrink={true}>
                          Reimburse To
                        </InputLabel>
                        <Select
                          label='Reimburse To'
                          labelId='reimburse-to-label'
                          notched={true}
                          value={reimburseToUserId || 'none'}
                          onChange={(e) => changeReimburseToUserId(e.target.value)}
                          onBlur={() => setReimburseToUserIdTouched(true)}
                        >
                          <MenuItem value='none'>None</MenuItem>
                          {orgUsers
                            .filter((u) => {
                              return !u.isDisabled && u.isRegistered && u.isVerified;
                            })
                            .map((u) => (
                              <MenuItem key={u.id} value={u.id}>
                                {`${u.firstName!} ${u.lastName!} (${u.email!})`}
                              </MenuItem>
                            ))}
                          <Divider />
                          <MenuItem onClick={() => setInviteUserDialogOpen(true)}>
                            <Add size='1rem' style={{ marginRight: theme.spacing(SMALL_HORIZONTAL_SPACING) }} />
                            <span>Invite User...</span>
                          </MenuItem>
                        </Select>
                        <FormHelperText>
                          {reimburseToUserIdTouched && reimburseToUserIdError && !inviteUserDialogOpen && !queuedUserInvite
                            ? 'User Selection Required'
                            : ''}
                        </FormHelperText>
                      </FormControl>
                    </Collapse>
                  </Box>

                  <InviteUserDialog
                    open={inviteUserDialogOpen}
                    onClose={() => setInviteUserDialogOpen(false)}
                    onInvite={async (email, role) => {
                      setQueuedUserInvite({
                        email,
                        role,
                      });
                      setInviteUserDialogOpen(false);
                      await Promise.resolve();
                    }}
                  />

                  <ConfirmDialog
                    title='Confirm User Invite'
                    open={!!queuedUserInvite}
                    onClose={() => setQueuedUserInvite(null)}
                    onConfirm={confirmQueuedUserInvite}
                    onConfirmLabel='Ok'
                    message='This user will need to accept their invitation to Otter before a reimbursement can be approved for them. We will remind you about this document later.'
                  />
                </>
              )}
          </Stack>
        </Stack>

        <DocumentImg
          document={document}
          containerHeight={128}
          containerWidth={128}
          imgFillContainer
          containerStyle={{
            cursor: 'pointer',
          }}
          onClick={() => setShowDocumentImageDialog(true)}
        />
        <DocumentImageDialog openFor={showDocumentImageDialog ? document : null} onClose={() => setShowDocumentImageDialog(false)} />
      </OutlineContainerSection>
    );
  }
);

interface DocumentDescriptionContentProps {
  document: Document;
  onDescriptionProvided: (description: string) => void;
  style?: CSSProperties;
}
const DocumentDescriptionContent = forwardRef<HTMLInputElement, DocumentDescriptionContentProps>(
  ({ document, onDescriptionProvided, style }, ref) => {
    const screenSize = useScreenSize();
    const [showDocumentImageDialog, setShowDocumentImageDialog] = useState(false);
    const [description, setDescription, descriptionTouched, setDescriptionTouched] = useFormState('');
    const keysDown = useRef<{ [key: string]: boolean }>({});

    const placeholder = useMemo(() => {
      const exampleDescriptions = [
        `This is an airline ticket I purchased`,
        `I bought some office supplies`,
        `Lunch for a team event`,
        `Monthly fee for a software subscription`,
        `This was a gift I bought for a client`,
      ];

      const index = Math.floor(Math.random() * exampleDescriptions.length);

      return `E.g. ${exampleDescriptions[index]}`;
    }, []);

    return (
      <OutlineContainerSection
        paddingX={SMALL_HORIZONTAL_SPACING}
        paddingY={SMALL_VERTICAL_SPACING}
        spacing={SMALL_VERTICAL_SPACING}
        direction='row'
        justifyContent='space-between'
        style={{
          ...style,
          flexDirection: screenSize !== ScreenSize.LARGE ? 'column' : 'row',
        }}
        ref={ref}
      >
        <Stack style={{ flex: 1, minWidth: 0 }}>
          <Typography>Describe this document so that I can categorize it appropriately</Typography>
          <TextField
            required
            fullWidth
            placeholder={placeholder}
            variant='standard'
            value={description}
            multiline
            onKeyDown={(e) => {
              keysDown.current[e.key] = true;

              if (
                e.key === 'Enter' &&
                (keysDown.current['Shift'] || keysDown.current['Option'] || keysDown.current['Alt'] || keysDown.current['Meta'])
              ) {
                e.stopPropagation();
              }
            }}
            onKeyUp={(e) => {
              delete keysDown.current[e.key];
            }}
            onBlur={() => setDescriptionTouched(true)}
            onChange={(e) => {
              setDescription(e.target.value);
              onDescriptionProvided(e.target.value);
            }}
          />
          <FormHelperText error>{!description && descriptionTouched && 'Please provide a description'}</FormHelperText>
        </Stack>

        <DocumentImg
          document={document}
          containerHeight={128}
          containerWidth={128}
          imgFillContainer
          containerStyle={{
            cursor: 'pointer',
          }}
          onClick={() => setShowDocumentImageDialog(true)}
        />
        <DocumentImageDialog openFor={showDocumentImageDialog ? document : null} onClose={() => setShowDocumentImageDialog(false)} />
      </OutlineContainerSection>
    );
  }
);

enum DocumentClarificationState {
  CHECK_UNMATCHED_REASON = 'CHECK_UNMATCHED_REASON',
  DOCUMENT_DESCRIPTION = 'DOCUMENT_DESCRIPTION',
  COMPLETE = 'COMPLETE',
}

const useDocumentClarificationState = ({
  organization,
  unmatched,
  requiresDescription,
  unmatchedReason,
  description,
  paymentAccount,
  reimburseToUserId,
  savedForLater,
}: {
  organization: Organization;
  unmatched: boolean;
  requiresDescription: boolean;
  unmatchedReason?: DocumentUnmatchedReason | null;
  paymentAccount?: string | null;
  reimburseToUserId?: string | null;
  description?: string | null;
  savedForLater: boolean | null;
}) => {
  const [prevState, setPrevState] = useState<DocumentClarificationState | null>(null);
  const [state, setState] = useState<DocumentClarificationState | null>(null);

  useEffect(() => {
    setPrevState(state);
  }, [state]);

  const nextDisabled = useMemo(() => {
    if (state === DocumentClarificationState.CHECK_UNMATCHED_REASON) {
      return (
        unmatchedReason === null ||
        (unmatchedReason === DocumentUnmatchedReason.PAYMENT_ACCOUNT_NUMBER && !paymentAccount) ||
        (unmatchedReason === DocumentUnmatchedReason.REIMBURSEMENT &&
          organization.type === OrganizationType.STARTUP &&
          organization.hasPayroll &&
          !reimburseToUserId)
      );
    } else if (state === DocumentClarificationState.DOCUMENT_DESCRIPTION) {
      return !description;
    }

    return false;
  }, [state, description, unmatchedReason, paymentAccount, reimburseToUserId, organization]);

  const next = useCallback(() => {
    if (savedForLater) {
      setState(DocumentClarificationState.COMPLETE);
    } else if (state === DocumentClarificationState.DOCUMENT_DESCRIPTION) {
      if (description) {
        setState(DocumentClarificationState.COMPLETE);
      }
    } else if (state === DocumentClarificationState.CHECK_UNMATCHED_REASON) {
      if (unmatchedReason) {
        if (requiresDescription && unmatchedReason !== DocumentUnmatchedReason.PERSONAL_EXPENSE) {
          setState(DocumentClarificationState.DOCUMENT_DESCRIPTION);
        } else if (
          (unmatchedReason !== DocumentUnmatchedReason.PAYMENT_ACCOUNT_NUMBER || paymentAccount) &&
          (!(
            unmatchedReason === DocumentUnmatchedReason.REIMBURSEMENT &&
            organization.type === OrganizationType.STARTUP &&
            organization.hasPayroll
          ) ||
            reimburseToUserId)
        ) {
          setState(DocumentClarificationState.COMPLETE);
        }
      }
    } else if (unmatched) {
      setState(DocumentClarificationState.CHECK_UNMATCHED_REASON);
    } else if (requiresDescription) {
      setState(DocumentClarificationState.DOCUMENT_DESCRIPTION);
    } else {
      setState(DocumentClarificationState.COMPLETE);
    }
  }, [state, description, savedForLater, requiresDescription, unmatchedReason, unmatched, paymentAccount, organization, reimburseToUserId]);

  useEffect(() => {
    if (!state) {
      next();
    }
  }, [state, next]);

  return {
    prevState,
    state,
    nextDisabled,
    next,
  };
};

interface DocumentClarificationProps {
  selected: boolean;
  document: Document & { account: Account | null };
  organization: Organization;
  orgUsers: User[];
  requiresDescription: boolean;
  unmatched: boolean;
  onSelect: () => void;
  onCompleteChange: (args: { isComplete: boolean; description?: string; unmatchedReason?: DocumentUnmatchedReason; savedForLater?: boolean }) => void;
  onInviteUser: (email: string, role: Role) => Promise<void>;
  style?: CSSProperties;
}

const DocumentClarification = forwardRef<HTMLDivElement, DocumentClarificationProps>(
  ({ document, organization, orgUsers, requiresDescription, unmatched, selected, onSelect, onCompleteChange, onInviteUser, style }, ref) => {
    const { session } = useSession();
    const outlineContainerBorder = useOutlineContainerBorder();
    const theme = useTheme();
    const { clarify } = useDocument();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const [accountDetailsDialogOpen, setAccountDetailsDialogOpen] = useState(false);

    const isExpense = parseFloat(document.afterTax!) > 0;

    const [description, setDescription] = useState<string | null>(null);
    const [unmatchedReason, setUnmatchedReason] = useState<DocumentUnmatchedReason | null>(null);
    const [paymentAccount, setPaymentAccount] = useState<string | null>(null);
    const [reimburseToUserId, setReimburseToUserId] = useState<string | null | undefined>(undefined);
    const [savedForLater, setSavedForLater] = useState<boolean | null>(null);

    const { prevState, state, nextDisabled, next } = useDocumentClarificationState({
      organization,
      unmatched,
      requiresDescription,
      unmatchedReason,
      description,
      savedForLater,
      paymentAccount,
      reimburseToUserId,
    });

    useEffect(() => {
      if (savedForLater) {
        next();
      }
    }, [next, savedForLater]);

    const sendClarification = useCallback(async () => {
      try {
        setLoading(true);

        await clarify({
          documentId: document.id,
          description: description !== null ? description : undefined,
          unmatchedReason: unmatchedReason !== null ? unmatchedReason : undefined,
          paymentAccount: paymentAccount !== null ? paymentAccount : undefined,
          reimburseToUserId: reimburseToUserId ? reimburseToUserId : undefined,
          savedForLater: savedForLater !== null ? savedForLater : undefined,
        });
      } catch (e) {
        setError('An error has occurred. Please contact support.');
        throw e;
      } finally {
        setLoading(false);
      }
    }, [clarify, description, document, unmatchedReason, paymentAccount, savedForLater, reimburseToUserId]);

    useEffect(() => {
      if (prevState !== DocumentClarificationState.COMPLETE && state === DocumentClarificationState.COMPLETE) {
        sendClarification()
          .then(() => {
            onCompleteChange({
              isComplete: true,
              description: description !== null ? description : undefined,
              unmatchedReason: unmatchedReason !== null ? unmatchedReason : undefined,
              savedForLater: savedForLater !== null ? savedForLater : undefined,
            });
          })
          .catch((e) => {
            throw e;
          });
      } else if (prevState === DocumentClarificationState.COMPLETE && state !== DocumentClarificationState.COMPLETE) {
        onCompleteChange({
          isComplete: false,
        });
      }
    }, [prevState, state, onCompleteChange, sendClarification, description, savedForLater, unmatchedReason]);

    const onEnter = useCallback(
      (e: KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter' && !nextDisabled) {
          e.preventDefault();
          next();
        }
      },
      [next, nextDisabled]
    );

    const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: document.currency });

    let accountDescription: React.ReactNode;
    if (isExpense) {
      if (document.account) {
        accountDescription = (
          <Typography>
            Charged to your{' '}
            <Tooltip title={document.account.name}>
              <Link sx={{ cursor: 'pointer' }} onClick={() => setAccountDetailsDialogOpen(true)}>
                {restrictLength(document.account.name, 30)}
              </Link>
            </Tooltip>{' '}
            account
          </Typography>
        );
      } else {
        accountDescription = (
          <Typography>
            Charged to an{' '}
            <Tooltip title='Unknown Account'>
              <Link color={theme.palette.warning.main} style={{ textDecorationColor: theme.palette.warning.main }}>
                Unknown Account {document.paymentAccountNumber ? `(${document.paymentAccountNumber})` : ''}
              </Link>
            </Tooltip>{' '}
            account
          </Typography>
        );
      }
    } else {
      if (document.account) {
        accountDescription = (
          <Typography>
            Deposited to your{' '}
            <Tooltip title={document.account.name}>
              <Link sx={{ cursor: 'pointer' }} onClick={() => setAccountDetailsDialogOpen(true)}>
                {restrictLength(document.account.name, 30)}
              </Link>
            </Tooltip>{' '}
            account
          </Typography>
        );
      } else {
        accountDescription = (
          <Typography>
            Deposited to an{' '}
            <Tooltip title='Unknown Account'>
              <Link color={theme.palette.warning.main} style={{ textDecorationColor: theme.palette.warning.main }}>
                Unknown Account {document.paymentAccountNumber ? `(${document.paymentAccountNumber})` : ''}
              </Link>
            </Tooltip>{' '}
            account
          </Typography>
        );
      }
    }

    const content = (
      <Collapse in={state !== DocumentClarificationState.COMPLETE} timeout={300} mountOnEnter unmountOnExit>
        <DelayFade
          in={state === DocumentClarificationState.CHECK_UNMATCHED_REASON}
          timeout={300}
          delayMs={state === DocumentClarificationState.CHECK_UNMATCHED_REASON ? 300 : undefined}
          mountOnEnter
          unmountOnExit
        >
          <DocumentUnmatchedClarificationContent
            document={document}
            organization={organization}
            orgUsers={orgUsers}
            onUnmatchedReasonProvided={(unmatchedReason) => setUnmatchedReason(unmatchedReason)}
            onPaymentAccountSpecified={(paymentAccount) => setPaymentAccount(paymentAccount)}
            onReimburseToUserIdSpecified={(userId) => setReimburseToUserId(userId)}
            onInviteUser={async (email, role) => {
              await onInviteUser(email, role);
              setSavedForLater(true);
            }}
          />
        </DelayFade>

        <DelayFade
          in={state === DocumentClarificationState.DOCUMENT_DESCRIPTION}
          timeout={300}
          delayMs={state === DocumentClarificationState.DOCUMENT_DESCRIPTION ? 300 : undefined}
          mountOnEnter
          unmountOnExit
        >
          <DocumentDescriptionContent document={document} onDescriptionProvided={(description) => setDescription(description)} />
        </DelayFade>
      </Collapse>
    );

    let footer: React.ReactNode;
    if (state !== DocumentClarificationState.COMPLETE) {
      footer = (
        <Stack width='100%' direction='row' justifyContent='space-between' alignItems='center' spacing={SMALL_HORIZONTAL_SPACING}>
          <Button variant='text' color='primary' onClick={() => setSavedForLater(true)}>
            <Stack direction='row' alignItems='center' spacing={1}>
              <Clock size='1rem' color={theme.palette.text.primary} style={{ flexShrink: 0 }} />
              <Typography variant='button' color={theme.palette.text.primary}>
                Save for later
              </Typography>
            </Stack>
          </Button>

          <Button variant='contained' color='primary' disabled={nextDisabled} onClick={next}>
            <Stack direction='row' alignItems='center' spacing={1}>
              <Typography variant='button' color={theme.palette.primary.contrastText}>
                Next
              </Typography>
              <ArrowRight size='1rem' color={theme.palette.primary.contrastText} />
            </Stack>
          </Button>
        </Stack>
      );
    } else {
      footer = <DocumentClarificationCompleteContent loading={loading} error={error || undefined} />;
    }

    let background: string;
    if (error) {
      background = alpha(theme.palette.error.main, 0.15);
    } else if (selected) {
      background = alpha(theme.palette.primary.main, 0.05);
    } else {
      background = theme.palette.background.default;
    }

    return (
      <Fade in={true}>
        <OutlineContainer
          ref={ref}
          background={background}
          onClick={onSelect}
          style={{
            position: 'relative',
            ...style,
          }}
          onKeyDown={onEnter}
        >
          <OutlineContainerSection
            borderBottom={outlineContainerBorder}
            paddingX={SMALL_HORIZONTAL_SPACING}
            paddingY={SMALL_VERTICAL_SPACING}
            alignItems='start'
          >
            <Stack spacing={0} alignItems='center'>
              {document.date && (
                <>
                  <Typography variant='small'>{formatInTimeZone(document.date, session?.timeZone || 'UTC', 'MMM').toUpperCase()}</Typography>
                  <Typography>{formatInTimeZone(document.date, session?.timeZone || 'UTC', 'dd')}</Typography>
                </>
              )}
              {!document.date && (
                <>
                  <Typography variant='small'>Unknown</Typography>
                  <Typography variant='small'>Date</Typography>
                </>
              )}
            </Stack>

            <Stack spacing={0} flex={1}>
              <Typography variant='h4'>{document.merchantName}</Typography>

              {accountDescription}

              <AccountDetailsDialog open={accountDetailsDialogOpen} onClose={() => setAccountDetailsDialogOpen(false)} account={document.account} />
            </Stack>

            <Stack spacing={0}>
              <Typography align='right'>{amountFormatter.format(Math.abs(documentTotal(document)))}</Typography>
              <Chip
                size='small'
                sx={{
                  fontSize: '0.666rem',
                }}
                color={isExpense ? 'error' : 'primary'}
                label={isExpense ? 'EXPENSE' : 'INCOME'}
              />
            </Stack>
          </OutlineContainerSection>

          {content}

          <OutlineContainerSection borderTop={outlineContainerBorder} paddingX={SMALL_HORIZONTAL_SPACING} paddingY={SMALL_VERTICAL_SPACING}>
            {footer}
          </OutlineContainerSection>
        </OutlineContainer>
      </Fade>
    );
  }
);

const useOrganizationData = () => {
  const { organization } = useSession();

  const { users, fetchUsers, inviteUser } = useOrganization();

  useEffect(() => {
    fetchUsers().catch((e) => {
      throw e;
    });
  }, [organization, fetchUsers]);

  return {
    organization,
    organizationUsers: users,
    inviteUser,
  };
};

export function DocumentClarificationWidget({ isSuccess }: IWidgetProps) {
  const theme = useTheme();
  const data = useConversationData();
  const { organization, organizationUsers, inviteUser } = useOrganizationData();
  const outlineContainerBorder = useOutlineContainerBorder();
  const { conversationId, addConversationMessage, scrollLockMutable } = useConversation();

  const [selectedDocument, setSelectedDocument] = useState<string | null>(null);
  const [completed, setCompleted] = useState(new Set<string>());

  useEffect(() => {
    if (data) {
      setCompleted(new Set(data.documentsCompleted));
    }
  }, [data]);

  const complete = async ({
    id,
    savedForLater,
    unmatchedReason,
    description,
  }: {
    id: string;
    savedForLater?: boolean;
    unmatchedReason?: DocumentUnmatchedReason;
    description?: string;
  }) => {
    setCompleted((existing) => {
      const newSet = new Set(existing);
      newSet.add(id);
      return newSet;
    });

    await addConversationMessage({
      conversationId: conversationId!,
      includeMessageInConversation: false,
      message: JSON.stringify({
        id,
        savedForLater,
        unmatchedReason,
        description,
      }),
    });
  };

  const incomplete = (id: string) => {
    setCompleted((existing) => {
      const newSet = new Set(existing);
      newSet.delete(id);
      return newSet;
    });
  };

  let totalDocuments = 0;
  let documentContent: ReactNode;
  if (!data || !organization || !organizationUsers) {
    documentContent = (
      <TransitionGroup component={null}>
        <Fade timeout={{ enter: 250, exit: 0 }} mountOnEnter unmountOnExit>
          <DocumentPlaceholder />
        </Fade>
        <Fade timeout={{ enter: 250, exit: 0 }} mountOnEnter unmountOnExit>
          <DocumentPlaceholder />
        </Fade>
        <Fade timeout={{ enter: 250, exit: 0 }} mountOnEnter unmountOnExit>
          <DocumentPlaceholder />
        </Fade>
      </TransitionGroup>
    );
  } else {
    totalDocuments = data.unmatchedDocuments.length;

    const documentsRequiringDescriptions = new Set(data.documentsRequiringDescriptions.map((d) => d.id));
    const unmatchedDocuments = new Set(data.unmatchedDocuments.map((d) => d.id));

    let documentsRequiringClarification = [];
    for (const document of data.unmatchedDocuments) {
      documentsRequiringClarification.push(document);
    }
    for (const document of data.documentsRequiringDescriptions) {
      if (!unmatchedDocuments.has(document.id)) {
        documentsRequiringClarification.push(document);
      }
    }

    documentsRequiringClarification = documentsRequiringClarification.filter((d) => !completed.has(d.id));

    documentContent = (
      <TransitionGroup component={null}>
        {documentsRequiringClarification.map((d, i) => (
          <DelayCollapse
            key={d.id}
            timeout={250}
            delayMs={{ enter: 250 * i, exit: 0 }}
            mountOnEnter
            unmountOnExit
            onEnter={() => (scrollLockMutable.current = false)}
            onEntered={() => (scrollLockMutable.current = true)}
            onExiting={() => (scrollLockMutable.current = false)}
            onExited={() => (scrollLockMutable.current = true)}
          >
            <DocumentClarification
              selected={selectedDocument === d.id}
              document={d}
              organization={organization}
              orgUsers={organizationUsers}
              unmatched={unmatchedDocuments.has(d.id)}
              requiresDescription={documentsRequiringDescriptions.has(d.id)}
              onSelect={() => setSelectedDocument(d.id)}
              onCompleteChange={async (completionDetails) => {
                if (completionDetails.isComplete) {
                  await complete({
                    id: d.id,
                    savedForLater: completionDetails.savedForLater,
                    unmatchedReason: completionDetails.unmatchedReason,
                    description: completionDetails.description,
                  });
                } else {
                  incomplete(d.id);
                }
              }}
              onInviteUser={inviteUser}
            />
          </DelayCollapse>
        ))}
      </TransitionGroup>
    );
  }

  return (
    <Fade in={true}>
      <OutlineContainer>
        <OutlineContainerSection
          justifyContent='space-between'
          background={theme.palette.background.default}
          borderBottom={completed.size < totalDocuments ? outlineContainerBorder : undefined}
        >
          <DelayFade
            in={!isSuccess && !!totalDocuments && completed.size < totalDocuments}
            delayMs={{ exit: 1000 }}
            timeout={{ exit: 300 }}
            mountOnEnter
            unmountOnExit
          >
            <Typography variant='h3'>Documents to review</Typography>
          </DelayFade>

          <DelayFade
            in={completed.size === totalDocuments}
            delayMs={{ enter: isSuccess ? 0 : 1300 }}
            timeout={{ enter: 300 }}
            mountOnEnter
            unmountOnExit
          >
            <Stack direction='row'>
              <Typography variant='h3'>Documents reviewed</Typography>

              <CheckCircle style={{ color: theme.palette.primary.main }} />
            </Stack>
          </DelayFade>

          <DelayFade
            in={!isSuccess && !!totalDocuments && completed.size < totalDocuments}
            delayMs={{ exit: 1000 }}
            timeout={{ exit: 300 }}
            mountOnEnter
            unmountOnExit
          >
            <ProgressBar complete={completed.size} total={totalDocuments} />
          </DelayFade>
        </OutlineContainerSection>

        <Collapse in={completed.size < totalDocuments} timeout={300} mountOnEnter unmountOnExit>
          <OutlineContainerSection direction='column' alignItems='stretch' spacing={SMALL_VERTICAL_SPACING}>
            {documentContent}
          </OutlineContainerSection>
        </Collapse>
      </OutlineContainer>
    </Fade>
  );
}
