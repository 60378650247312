import {
  Avatar,
  AvatarGroup,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { format } from 'date-fns';
import { ArrowLeft2, ArrowRight2, CloseCircle } from 'iconsax-react';
import { useEffect, useMemo, useState } from 'react';
import { Organization, OrganizationEvent, useAdmin } from '../../../api';
import { Calendar, PageBody, PageContainer, PageHeader, ThreeColumn } from '../../../components';
import { OutlineContainer, OutlineContainerSection } from '../../../components/outline-container';

const monthMap = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
} as { [key: number]: string };

function dateKey(date: Date) {
  return `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`;
}

const useAdminData = (year: number, month: number) => {
  const { fetchOrganizationEvents, fetchOrganizations, organizationEvents, organizations } = useAdmin();

  useEffect(() => {
    fetchOrganizations().catch((e) => {
      throw e;
    });

    fetchOrganizationEvents(year, month).catch((e) => {
      throw e;
    });
  }, [fetchOrganizationEvents, fetchOrganizations, year, month]);

  const organizationsById = useMemo(() => {
    if (!organizations) {
      return null;
    }

    return organizations.reduce(
      (map, current) => {
        map[current.id!] = current;
        return map;
      },
      {} as { [orgId: string]: Organization }
    );
  }, [organizations]);

  const events = useMemo(() => {
    if (!organizationEvents[year] || !organizationEvents[year][month]) {
      return null;
    }

    const events = organizationEvents[year][month];

    const eventsByDay = {} as { [dateString: string]: OrganizationEvent[] };
    for (const event of events) {
      const dateString = dateKey(event.timestamp);

      if (!eventsByDay[dateString]) {
        eventsByDay[dateString] = [];
      }

      eventsByDay[dateString].push(event);
    }

    return eventsByDay;
  }, [organizationEvents, year, month]);

  return {
    events,
    organizationsById,
  };
};

interface DayEventsDialogProps {
  openForDay: {
    year: number;
    month: number;
    day: number;
  } | null;
  events: {
    [dateString: string]: OrganizationEvent[];
  };
  onClose: () => void;
  organizationsById: { [orgId: string]: Organization };
}

function DayEventsDialog({ openForDay, onClose, events, organizationsById }: DayEventsDialogProps) {
  const theme = useTheme();
  const dateString = openForDay ? dateKey(new Date(openForDay.year, openForDay.month - 1, openForDay.day)) : null;

  const content =
    dateString && events[dateString] ? (
      events[dateString].map((e) => {
        const organization = organizationsById[e.organizationId];

        let status: string;
        if (e.complete) {
          status = 'Complete';
        } else if (e.started) {
          status = 'Started';
        } else {
          status = 'Not Started';
        }

        return (
          <OutlineContainer>
            <OutlineContainerSection>
              <Typography>{format(e.timestamp, 'hh:mm a')}</Typography>
              <Typography>{organization.name}</Typography>
              <Typography>{e.type}</Typography>
              <Typography>{status}</Typography>
            </OutlineContainerSection>
          </OutlineContainer>
        );
      })
    ) : (
      <Typography padding={theme.spacing(10)}>No events</Typography>
    );

  return (
    <Dialog open={!!openForDay} onClose={onClose}>
      {openForDay && (
        <>
          <DialogTitle>
            <ThreeColumn $mainColumn align='center'>
              <span></span>
              <span>{format(new Date(openForDay.year, openForDay.month - 1, openForDay.day), 'MMMM d, yyyy')}</span>
              <IconButton onClick={onClose}>
                <CloseCircle />
              </IconButton>
            </ThreeColumn>
          </DialogTitle>
          <DialogContent>
            <Stack overflow='auto' alignItems='center'>
              {content}
            </Stack>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}

export function AdminCalendarPage({ ...props }) {
  const now = new Date();

  const theme = useTheme();
  const [year, setYear] = useState(now.getFullYear());
  const [month, setMonth] = useState(now.getMonth() + 1);

  const [selectedDay, setSelectedDay] = useState<null | { day: number; month: number; year: number }>(null);

  const { organizationsById, events } = useAdminData(year, month);

  const [dayOpen, setDayOpen] = useState<{ year: number; month: number; day: number } | null>(null);

  const years = useMemo(() => {
    const y: number[] = [];
    for (let i = 2023; i < new Date().getFullYear() + 1; i++) {
      y.push(i);
    }
    return y;
  }, []);

  if (!events || !organizationsById) {
    return (
      <PageContainer {...props}>
        <PageHeader title='Admin - Statements' />
        <PageBody gutter='thin'>
          <Stack alignItems='center' justifyContent='center' height='100%'>
            <CircularProgress />
          </Stack>
        </PageBody>
      </PageContainer>
    );
  }

  return (
    <PageContainer {...props}>
      <PageHeader title='Admin - Calendar' />
      <PageBody gutter='thin'>
        <Stack height='100%' paddingTop={theme.spacing(3)}>
          <Stack direction='row' alignItems='center'>
            <FormControl>
              <InputLabel id='month-label'>Month</InputLabel>
              <Select
                label='Month'
                labelId='month-label'
                value={month}
                sx={{
                  width: 128,
                }}
                onChange={(e) => setMonth(Number(e.target.value))}
              >
                {Array.from({ length: 12 }, (_, index) => index + 1).map((m) => (
                  <MenuItem key={`month-${m}`} value={m}>
                    {monthMap[m]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel id='year-label'>Year</InputLabel>
              <Select label='Year' labelId='year-label' value={year} onChange={(e) => setYear(Number(e.target.value))}>
                {years.map((y) => (
                  <MenuItem key={`year-${y}`} value={y}>
                    {y}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <IconButton
              onClick={() => {
                setMonth((current) => {
                  if (current === 1) {
                    return 12;
                  } else {
                    return current - 1;
                  }
                });
              }}
            >
              <ArrowLeft2 size='1rem' color={theme.palette.text.primary} />
            </IconButton>

            <IconButton
              onClick={() => {
                setMonth((current) => {
                  if (current === 12) {
                    return 1;
                  } else {
                    return current + 1;
                  }
                });
              }}
            >
              <ArrowRight2 size='1rem' color={theme.palette.text.primary} />
            </IconButton>
          </Stack>

          <Calendar
            year={year}
            month={month}
            selectedDay={selectedDay}
            style={{ flex: 1 }}
            dayChildren={(year, month, day) => {
              const dateString = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

              if (!events[dateString]) {
                return null;
              }

              return (
                <Stack width='100%' alignItems='center'>
                  <AvatarGroup
                    max={3}
                    slotProps={{
                      additionalAvatar: {
                        sx: {
                          width: 32,
                          height: 32,
                          fontSize: '1rem',
                        },
                      },
                    }}
                  >
                    {events[dateString]
                      .sort((a, b) => {
                        const aIncomplete = a.started && !a.complete;
                        const bIncomplete = b.started && !b.complete;
                        if (aIncomplete && bIncomplete) {
                          return organizationsById[a.organizationId].name.localeCompare(organizationsById[b.organizationId].name);
                        } else if (aIncomplete) {
                          return -1;
                        } else if (bIncomplete) {
                          return 1;
                        } else if (!a.started && b.started) {
                          return -1;
                        } else if (!b.started && a.started) {
                          return 1;
                        }

                        return organizationsById[a.organizationId].name.localeCompare(organizationsById[b.organizationId].name);
                      })
                      .map((e) => {
                        const organization = organizationsById[e.organizationId];
                        let bgColor: string | undefined;
                        let textColor: string | undefined;
                        if (e.complete) {
                          bgColor = undefined;
                          textColor = undefined;
                        } else if (e.started) {
                          bgColor = theme.palette.error.main;
                          textColor = theme.palette.error.contrastText;
                        } else {
                          bgColor = theme.palette.warning.main;
                          textColor = theme.palette.warning.contrastText;
                        }

                        return (
                          <Avatar
                            key={e.id}
                            alt={organization.name}
                            src={''}
                            sx={{
                              width: 32,
                              height: 32,
                              bgcolor: bgColor,
                              color: textColor,
                            }}
                          >
                            {organization.name.charAt(0)}
                          </Avatar>
                        );
                      })}
                  </AvatarGroup>
                </Stack>
              );
            }}
            onDayClicked={(year, month, day) => {
              setSelectedDay({
                year,
                month,
                day,
              });
              setDayOpen({
                year,
                month,
                day,
              });
            }}
          />

          <DayEventsDialog openForDay={dayOpen} onClose={() => setDayOpen(null)} events={events} organizationsById={organizationsById} />
        </Stack>
      </PageBody>
    </PageContainer>
  );
}
