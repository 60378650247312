import { CheckCircle, MailOutline } from '@mui/icons-material';
import {
  Alert,
  Chip,
  CircularProgress,
  Collapse,
  Fade,
  FormHelperText,
  IconButton,
  Link,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';
import { formatInTimeZone } from 'date-fns-tz';
import { ArrowRight, Clock, CloseCircle, DocumentUpload, Receipt, TickCircle, Warning2 } from 'iconsax-react';
import React, { CSSProperties, KeyboardEvent, ReactNode, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { Account, SerializedTx, Transaction, deserializeTransaction, useConversation, useLedger, useSession, useTransaction } from '../../../api';
import { useCurrentConversation } from '../../../pages/user/current-conversation-context';
import { SMALL_HORIZONTAL_SPACING, SMALL_VERTICAL_SPACING } from '../../../theme';
import { getFiscalYear } from '../../../utils/date-utils';
import { ScreenSize, useScreenSize } from '../../../utils/use-screen-size';
import { useFormState } from '../../../utils/useFormState';
import { DelayCollapse, DelayFade } from '../../animations';
import { Button } from '../../button';
import { DocumentTipsDialog } from '../../document-tips-dialog';
import { OutlineContainer, OutlineContainerSection, useOutlineContainerBorder } from '../../outline-container';
import { ProgressBar } from '../../small-progress-bar';
import { IWidgetProps } from '../IWidgetProps';
import { AccountDetailsDialog, restrictLength } from './clarification';

const useConversationData = () => {
  const { data } = useCurrentConversation();
  const { fetchAccounts, fetchJournals, journals, accounts } = useLedger();
  const { organization } = useSession();

  useEffect(() => {
    fetchJournals().catch((e) => {
      throw e;
    });
  }, [fetchJournals]);

  const formattedData = useMemo<null | {
    transactionsCompleted: string[];
    transactionsRequiringDescriptions: Transaction[];
    transactionsRequiringDocuments: Transaction[];
  }>(() => {
    if (data === null) {
      return null;
    }

    const castedData = data as {
      transactionsCompleted: string[];
      transactionsRequiringDescriptions: SerializedTx[];
      transactionsRequiringDocuments: SerializedTx[];
    };

    return {
      ...castedData,
      transactionsRequiringDescriptions: castedData.transactionsRequiringDescriptions.map(deserializeTransaction),
      transactionsRequiringDocuments: castedData.transactionsRequiringDocuments.map(deserializeTransaction),
    };
  }, [data]);

  useEffect(() => {
    if (!formattedData || !journals || !organization) {
      return;
    }

    const fys = new Set<string>();
    for (const tx of formattedData.transactionsRequiringDescriptions) {
      fys.add(getFiscalYear(tx.date, organization.fyEndMonth));
    }
    for (const tx of formattedData.transactionsRequiringDocuments) {
      fys.add(getFiscalYear(tx.date, organization.fyEndMonth));
    }

    for (const fy of fys) {
      const journal = journals.find((j) => j.fy === fy);
      if (!journal) {
        throw new Error(`No journal for fy ${fy}`);
      }

      fetchAccounts(journal.id).catch((e) => {
        throw e;
      });
    }
  }, [journals, formattedData, organization, fetchAccounts]);

  const conversationData = useMemo<null | {
    transactionsCompleted: string[];
    transactionsRequiringDescriptions: (Transaction & { account: Account })[];
    transactionsRequiringDocuments: (Transaction & { account: Account })[];
  }>(() => {
    if (!formattedData || !organization || !journals) {
      return null;
    }

    const getAccount = (transaction: Transaction) => {
      const fy = getFiscalYear(transaction.date, organization.fyEndMonth);

      const journal = journals.find((j) => j.fy === fy);
      if (!journal) {
        throw new Error(`No journal for fy ${fy}`);
      }

      const fyAccounts = accounts[journal.id];
      if (!fyAccounts) {
        return null;
      }

      const account = fyAccounts.find((a) => a.externalId === transaction.accountId);
      if (!account) {
        throw new Error(`No account for tx (account external id: ${transaction.accountId})`);
      }

      return account;
    };

    const transactionsRequiringDescriptions: (Transaction & { account: Account })[] = [];
    for (const tx of formattedData.transactionsRequiringDescriptions) {
      const account = getAccount(tx);
      if (!account) {
        return null;
      }

      transactionsRequiringDescriptions.push({
        ...tx,
        account,
      });
    }

    const transactionsRequiringDocuments: (Transaction & { account: Account })[] = [];
    for (const tx of formattedData.transactionsRequiringDocuments) {
      const account = getAccount(tx);
      if (!account) {
        return null;
      }

      transactionsRequiringDocuments.push({
        ...tx,
        account,
      });
    }

    return {
      transactionsCompleted: formattedData.transactionsCompleted,
      transactionsRequiringDescriptions,
      transactionsRequiringDocuments,
    };
  }, [formattedData, accounts, organization, journals]);

  return conversationData;
};

const TransactionPlaceholder = forwardRef<HTMLDivElement>(({ style }: { style?: CSSProperties }, ref) => {
  const outlineContainerBorder = useOutlineContainerBorder();
  const randomSeed = useRef(Math.random());
  const theme = useTheme();

  return (
    <OutlineContainer ref={ref} background={theme.palette.background.default} style={style}>
      <OutlineContainerSection borderBottom={outlineContainerBorder} paddingX={SMALL_HORIZONTAL_SPACING} paddingY={SMALL_VERTICAL_SPACING}>
        <Skeleton variant='rounded' width={32} height={32} />

        <Stack spacing={0} flex={1}>
          <Skeleton variant='text' width={128 + randomSeed.current * 128} />
          <Skeleton variant='text' width={128 + randomSeed.current * 256} />
        </Stack>

        <Stack spacing={0}>
          <Skeleton variant='text' width={64} />
          <Skeleton variant='text' width={64} />
        </Stack>
      </OutlineContainerSection>

      <OutlineContainerSection paddingX={SMALL_HORIZONTAL_SPACING} paddingY={SMALL_VERTICAL_SPACING}>
        <Skeleton width={64} />
        <Skeleton width={96} />
      </OutlineContainerSection>
    </OutlineContainer>
  );
});

interface TransactionClarificationCompleteContentProps {
  loading: boolean;
  error?: string;
  documentToBeSent?: boolean;
  savedForLater?: boolean;
}
function TransactionClarificationCompleteContent({ loading, error, documentToBeSent, savedForLater }: TransactionClarificationCompleteContentProps) {
  const theme = useTheme();

  let content: React.ReactNode;
  if (documentToBeSent) {
    content = (
      <Stack direction='row' alignItems='center' spacing={1}>
        <TickCircle size='1.5rem' color={theme.palette.primary.main} />
        <Typography>Okay, you'll send us a document later</Typography>
      </Stack>
    );
  } else if (savedForLater) {
    content = (
      <Stack direction='row' alignItems='center' spacing={1}>
        <Clock size='1.5rem' color={theme.palette.warning.main} />
        <Typography>You told me you would handle this later</Typography>
      </Stack>
    );
  } else {
    content = (
      <Stack direction='row' alignItems='center' spacing={1}>
        <TickCircle size='1.5rem' color={theme.palette.primary.main} />
        <Typography>This one's done!</Typography>
      </Stack>
    );
  }

  return (
    <Stack direction='row' alignItems='center' justifyContent='space-between' width='100%'>
      {content}
      {loading && <CircularProgress size='1rem' color='primary' />}
      {error && (
        <Tooltip title={error}>
          <Warning2 color={theme.palette.error.main} size='1.5rem' />
        </Tooltip>
      )}
    </Stack>
  );
}

interface TransactionDescriptionContentProps {
  transaction: Transaction;
  onDescriptionProvided: (description: string) => void;
  style?: CSSProperties;
}
const TransactionDescriptionContent = forwardRef<HTMLInputElement, TransactionDescriptionContentProps>(
  ({ transaction, onDescriptionProvided, style }, ref) => {
    const [description, setDescription, descriptionTouched, setDescriptionTouched] = useFormState('');
    const keysDown = useRef<{ [key: string]: boolean }>({});

    const placeholder = useMemo(() => {
      const exampleIncomeDescriptions = [
        `This was payment from a new client`,
        `Payment for Acme Inc.'s September invoice`,
        `I sold off laptops owned by the business`,
      ];
      const exampleExpenseDescriptions = [
        `This is an airline ticket I purchased`,
        `I bought some office supplies`,
        `Lunch for a team event`,
        `Monthly fee for a software subscription`,
        `This was a gift I bought for a client`,
      ];

      const isExpense = parseFloat(transaction.amount) > 0;
      const descriptions = isExpense ? exampleExpenseDescriptions : exampleIncomeDescriptions;

      const index = Math.floor(Math.random() * descriptions.length);

      return `E.g. ${descriptions[index]}`;
    }, [transaction]);

    return (
      <OutlineContainerSection
        paddingX={SMALL_HORIZONTAL_SPACING}
        paddingY={SMALL_VERTICAL_SPACING}
        direction='column'
        alignItems='start'
        style={style}
        ref={ref}
      >
        <Typography>Describe this transaction so that I can categorize it appropriately</Typography>
        <TextField
          required
          fullWidth
          placeholder={placeholder}
          variant='standard'
          value={description}
          multiline
          onKeyDown={(e) => {
            keysDown.current[e.key] = true;

            if (
              e.key === 'Enter' &&
              (keysDown.current['Shift'] || keysDown.current['Option'] || keysDown.current['Alt'] || keysDown.current['Meta'])
            ) {
              e.stopPropagation();
            }
          }}
          onKeyUp={(e) => {
            delete keysDown.current[e.key];
          }}
          onBlur={() => setDescriptionTouched(true)}
          onChange={(e) => {
            setDescription(e.target.value);
            onDescriptionProvided(e.target.value);
          }}
        />
        <FormHelperText error>{!description && descriptionTouched && 'Please provide a description'}</FormHelperText>
      </OutlineContainerSection>
    );
  }
);

const DocumentGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

interface TransactionDocumentContentProps {
  onDocumentsProvided: (files: File[]) => void;
  onHasDocumentsChange: (hasDocuments: boolean) => void;
  style?: CSSProperties;
}
const TransactionDocumentContent = forwardRef<HTMLInputElement, TransactionDocumentContentProps>(
  ({ onDocumentsProvided, onHasDocumentsChange, style }, ref) => {
    const theme = useTheme();
    const outlineContainerBorder = useOutlineContainerBorder();
    const screenSize = useScreenSize();

    const [fileRejections, setFileRejections] = useState<string[]>([]);
    const [files, setFiles] = useState<File[] | null>(null);
    const { getRootProps, getInputProps, inputRef } = useDropzone({
      accept: {
        'image/heic': [],
        'image/jpeg': [],
        'image/png': [],
        'application/pdf': [],
      },
      maxSize: 5242880, // 5MB
      onDrop: (acceptedFiles, fileRejections) => {
        const rejectionData = [];

        for (const fileRejection of fileRejections) {
          for (const error of fileRejection.errors) {
            if (error.code === 'file-too-large') {
              rejectionData.push(`${fileRejection.file.name} is larger than 5 MB.`);
            }
            if (error.code === 'file-invalid-type') {
              rejectionData.push(`File type is not correct for this institution's statements.`);
            }
          }
        }

        setFileRejections(rejectionData);
        setFiles(acceptedFiles);
      },
    });

    useEffect(() => {
      if (files) {
        onDocumentsProvided(files);
      }
    }, [files, onDocumentsProvided]);

    const [hasDocument, setHasDocument] = useState<null | boolean>(null);

    useEffect(() => {
      if (hasDocument === false || hasDocument === true) {
        onHasDocumentsChange(hasDocument);
      }
    }, [hasDocument, onHasDocumentsChange]);

    const [showEmailInstructions, setShowEmailInstructions] = useState(false);

    return (
      <>
        <OutlineContainerSection
          paddingX={SMALL_HORIZONTAL_SPACING}
          paddingY={SMALL_VERTICAL_SPACING}
          spacing={SMALL_VERTICAL_SPACING}
          direction='column'
          style={style}
          ref={ref}
        >
          <Typography>Do you have a receipt or invoice for this transaction?</Typography>
          <Stack direction={screenSize === ScreenSize.SMALL ? 'column' : 'row'} spacing={SMALL_HORIZONTAL_SPACING}>
            <Button
              variant={hasDocument ? 'contained' : 'outlined'}
              color={hasDocument ? 'secondary' : 'neutral'}
              onClick={() => setHasDocument(true)}
            >
              <Stack direction='row' alignItems='center' spacing={1}>
                <TickCircle
                  variant={hasDocument ? 'Bold' : 'Outline'}
                  size='1rem'
                  color={hasDocument ? theme.palette.secondary.contrastText : theme.palette.text.primary}
                />
                <Typography variant='button' color={hasDocument ? theme.palette.secondary.contrastText : theme.palette.text.primary}>
                  Yes, I do
                </Typography>
              </Stack>
            </Button>

            <Button
              variant={hasDocument === false ? 'contained' : 'outlined'}
              color={hasDocument === false ? 'secondary' : 'neutral'}
              onClick={() => setHasDocument(false)}
            >
              <Stack direction='row' alignItems='center' spacing={1}>
                <CloseCircle
                  variant={hasDocument === false ? 'Bold' : 'Outline'}
                  size='1rem'
                  color={hasDocument === false ? theme.palette.secondary.contrastText : theme.palette.text.primary}
                />
                <Typography variant='button' color={hasDocument === false ? theme.palette.secondary.contrastText : theme.palette.text.primary}>
                  No, I don't
                </Typography>
              </Stack>
            </Button>
          </Stack>
        </OutlineContainerSection>

        <Collapse in={hasDocument === true} timeout={300} mountOnEnter unmountOnExit>
          <OutlineContainerSection padding={0} borderTop={outlineContainerBorder} direction='column' spacing={SMALL_VERTICAL_SPACING}>
            <Stack
              direction='column'
              alignItems='center'
              paddingX={SMALL_HORIZONTAL_SPACING}
              paddingTop={SMALL_VERTICAL_SPACING}
              paddingBottom={0}
              spacing={SMALL_VERTICAL_SPACING}
              width='100%'
              {...getRootProps()}
              onClick={(e) => e.stopPropagation()}
            >
              <input style={{ display: 'none' }} {...getInputProps()} />

              <DocumentUpload size='6rem' color={theme.palette.primary.main} />

              <Typography variant='h3'>Drag and drop your file(s) here</Typography>
              <Typography>or</Typography>
              <Button
                variant='outlined'
                color='neutral'
                onClick={() => {
                  inputRef.current!.click();
                }}
              >
                Browse for files
              </Button>

              <Typography>
                You can also{' '}
                <Link
                  sx={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowEmailInstructions(true);
                  }}
                >
                  send your files by email
                </Link>
                .
              </Typography>
            </Stack>

            <DocumentGrid
              style={{
                width: '100%',
                paddingLeft: theme.spacing(SMALL_HORIZONTAL_SPACING),
                paddingRight: theme.spacing(SMALL_HORIZONTAL_SPACING),
                paddingBottom: theme.spacing(SMALL_VERTICAL_SPACING),
              }}
            >
              {(files || []).map((file, i) => (
                <Stack
                  key={`${file.name}-${i}`}
                  sx={{
                    background: theme.palette.background.default,
                  }}
                  borderRadius={theme.roundedCorners(5)}
                  border={`1px solid ${theme.palette.neutral.main}`}
                  direction='row'
                  alignItems='center'
                  paddingX={theme.spacing(SMALL_HORIZONTAL_SPACING)}
                  paddingY={theme.spacing(SMALL_VERTICAL_SPACING)}
                  justifyContent='space-between'
                >
                  <Receipt size='1rem' style={{ flexShrink: 0 }} />
                  <Tooltip title={file.name}>
                    <Typography>{restrictLength(file.name, 10, '-')}</Typography>
                  </Tooltip>
                  <IconButton
                    onClick={() => {
                      setFiles((existing) => {
                        return [...(existing || [])].filter((f) => f !== file);
                      });
                    }}
                  >
                    <CloseCircle />
                  </IconButton>
                </Stack>
              ))}
            </DocumentGrid>

            <Stack paddingX={SMALL_HORIZONTAL_SPACING} paddingY={SMALL_VERTICAL_SPACING}>
              {fileRejections.map((fileRejection) => (
                <Alert
                  icon={<Warning2 />}
                  severity='error'
                  style={{
                    alignItems: 'center',
                  }}
                  onClose={() => {
                    setFileRejections((existing) => [...existing].filter((r) => r !== fileRejection));
                  }}
                >
                  {fileRejection}
                </Alert>
              ))}
            </Stack>
          </OutlineContainerSection>

          <DocumentTipsDialog open={showEmailInstructions} onClose={() => setShowEmailInstructions(false)} />
        </Collapse>
      </>
    );
  }
);

enum TransactionClarificationState {
  DESCRIPTION = 'DESCRIPTION',
  DOCUMENTS = 'DOCUMENTS',
  COMPLETE = 'COMPLETE',
}

const useTransactionClarificationState = ({
  requiresDescription,
  requiresDocument,
  description,
  documentFiles,
  hasDocuments,
  sendDocumentsLater,
  savedForLater,
}: {
  requiresDescription?: boolean;
  requiresDocument?: boolean;
  description: string | null;
  documentFiles: File[] | null;
  hasDocuments: boolean | null;
  sendDocumentsLater: boolean;
  savedForLater: boolean;
}) => {
  const [prevState, setPrevState] = useState<TransactionClarificationState | null>(null);
  const [state, setState] = useState<TransactionClarificationState | null>(null);

  useEffect(() => {
    setPrevState(state);
  }, [state]);

  const nextDisabled = useMemo(() => {
    if (state === TransactionClarificationState.DESCRIPTION) {
      return !description;
    } else if (state === TransactionClarificationState.DOCUMENTS) {
      return !documentFiles && hasDocuments !== false && !sendDocumentsLater;
    }

    return false;
  }, [state, description, documentFiles, hasDocuments, sendDocumentsLater]);

  const next = useCallback(() => {
    if (savedForLater) {
      setState(TransactionClarificationState.COMPLETE);
    } else if (state === TransactionClarificationState.DESCRIPTION) {
      if (description) {
        if (requiresDocument) {
          setState(TransactionClarificationState.DOCUMENTS);
        } else {
          setState(TransactionClarificationState.COMPLETE);
        }
      }
    } else if (state === TransactionClarificationState.DOCUMENTS) {
      if (documentFiles || hasDocuments === false || sendDocumentsLater) {
        setState(TransactionClarificationState.COMPLETE);
      }
    } else {
      if (requiresDescription) {
        setState(TransactionClarificationState.DESCRIPTION);
      } else if (requiresDocument) {
        setState(TransactionClarificationState.DOCUMENTS);
      } else {
        setState(TransactionClarificationState.COMPLETE);
      }
    }
  }, [state, requiresDescription, requiresDocument, description, documentFiles, hasDocuments, sendDocumentsLater, savedForLater]);

  useEffect(() => {
    if (!state) {
      next();
    }
  }, [state, next]);

  return {
    prevState,
    state,
    nextDisabled,
    next,
  };
};

interface TransactionClarificationProps {
  selected: boolean;
  transaction: Transaction & { account: Account };
  requiresDescription: boolean;
  requiresDocument: boolean;
  onSelect: () => void;
  onCompleteChange: (args: {
    isComplete: boolean;
    descriptionProvided?: string;
    documentProvided?: boolean;
    awaitingDocumentEmail?: boolean;
    savedForLater?: boolean;
  }) => void;
  style?: CSSProperties;
}

const TransactionClarification = forwardRef<HTMLDivElement, TransactionClarificationProps>(
  ({ transaction, selected, onSelect, onCompleteChange, requiresDescription, requiresDocument, style }, ref) => {
    const { session } = useSession();
    const outlineContainerBorder = useOutlineContainerBorder();
    const theme = useTheme();
    const { clarify } = useTransaction();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const [accountDetailsDialogOpen, setAccountDetailsDialogOpen] = useState(false);

    const isExpense = parseFloat(transaction.amount) > 0;

    const [description, setDescription] = useState<string | null>(null);
    const [documentFiles, setDocumentFiles] = useState<File[] | null>(null);
    const [hasDocuments, setHasDocuments] = useState<boolean | null>(null);
    const [sendDocumentsLater, setSendDocumentsLater] = useState(false);
    const [savedForLater, setSavedForLater] = useState(false);

    const { prevState, state, nextDisabled, next } = useTransactionClarificationState({
      requiresDescription,
      requiresDocument,
      description,
      documentFiles,
      hasDocuments,
      sendDocumentsLater,
      savedForLater,
    });

    const sendClarification = useCallback(async () => {
      try {
        setLoading(true);

        await clarify({
          transactionId: transaction.id,
          description: description !== null ? description : undefined,
          documentFiles: documentFiles !== null ? documentFiles : undefined,
          noDocuments: hasDocuments !== null ? !hasDocuments : undefined,
          awaitingDocumentEmail: sendDocumentsLater !== null ? sendDocumentsLater : undefined,
          savedForLater: savedForLater !== null ? savedForLater : undefined,
        });
      } catch (e) {
        setError('An error has occurred. Please contact support.');
        throw e;
      } finally {
        setLoading(false);
      }
    }, [clarify, description, documentFiles, transaction, hasDocuments, sendDocumentsLater, savedForLater]);

    useEffect(() => {
      if (prevState !== TransactionClarificationState.COMPLETE && state === TransactionClarificationState.COMPLETE) {
        sendClarification()
          .then(() => {
            onCompleteChange({
              isComplete: true,
              descriptionProvided: description !== null ? description : undefined,
              documentProvided: documentFiles ? !!documentFiles.length : false,
              awaitingDocumentEmail: sendDocumentsLater !== null ? sendDocumentsLater : undefined,
              savedForLater: savedForLater !== null ? savedForLater : undefined,
            });
          })
          .catch((e) => {
            throw e;
          });
      } else if (prevState === TransactionClarificationState.COMPLETE && state !== TransactionClarificationState.COMPLETE) {
        onCompleteChange({
          isComplete: false,
        });
      }
    }, [prevState, state, onCompleteChange, sendClarification, description, documentFiles, sendDocumentsLater, savedForLater]);

    useEffect(() => {
      if (sendDocumentsLater && state === TransactionClarificationState.DOCUMENTS) {
        next();
      }
    }, [sendDocumentsLater, next, state]);

    useEffect(() => {
      if (savedForLater) {
        next();
      }
    }, [savedForLater, next]);

    const onEnter = useCallback(
      (e: KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter' && !nextDisabled) {
          e.preventDefault();
          next();
        }
      },
      [next, nextDisabled]
    );

    const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: transaction.isoCurrencyCode });

    let accountDescription: React.ReactNode;
    if (isExpense) {
      accountDescription = (
        <Typography>
          Charged to your{' '}
          <Tooltip title={transaction.account.name}>
            <Link sx={{ cursor: 'pointer' }} onClick={() => setAccountDetailsDialogOpen(true)}>
              {restrictLength(transaction.account.name, 30)}
            </Link>
          </Tooltip>{' '}
          account
        </Typography>
      );
    } else {
      accountDescription = (
        <Typography>
          Deposited to your{' '}
          <Tooltip title={transaction.account.name}>
            <Link sx={{ cursor: 'pointer' }} onClick={() => setAccountDetailsDialogOpen(true)}>
              {restrictLength(transaction.account.name, 30)}
            </Link>
          </Tooltip>{' '}
          account
        </Typography>
      );
    }

    const content = (
      <Collapse in={state !== TransactionClarificationState.COMPLETE} timeout={300} mountOnEnter unmountOnExit>
        <DelayFade
          in={state === TransactionClarificationState.DESCRIPTION}
          timeout={300}
          delayMs={state === TransactionClarificationState.DESCRIPTION ? 300 : undefined}
          mountOnEnter
          unmountOnExit
        >
          <TransactionDescriptionContent transaction={transaction} onDescriptionProvided={(description) => setDescription(description)} />
        </DelayFade>

        <DelayFade
          in={state === TransactionClarificationState.DOCUMENTS}
          timeout={300}
          delayMs={state === TransactionClarificationState.DOCUMENTS ? 300 : undefined}
          mountOnEnter
          unmountOnExit
        >
          <TransactionDocumentContent
            onDocumentsProvided={(files) => setDocumentFiles(files)}
            onHasDocumentsChange={(hasDocuments) => setHasDocuments(hasDocuments)}
          />
        </DelayFade>
      </Collapse>
    );

    let footer: React.ReactNode;
    if (state !== TransactionClarificationState.COMPLETE) {
      footer = (
        <Stack width='100%' direction='row' justifyContent='space-between' alignItems='center' spacing={SMALL_HORIZONTAL_SPACING}>
          <Button variant='text' color='primary' onClick={() => setSavedForLater(true)}>
            <Stack direction='row' alignItems='center' spacing={1}>
              <Clock size='1rem' color={theme.palette.text.primary} style={{ flexShrink: 0 }} />
              <Typography variant='button' color={theme.palette.text.primary}>
                Save for later
              </Typography>
            </Stack>
          </Button>

          <Stack direction='row' alignItems='center' spacing={SMALL_HORIZONTAL_SPACING}>
            {state === TransactionClarificationState.DOCUMENTS && !documentFiles && hasDocuments && !sendDocumentsLater && (
              <Button variant='text' color='primary' onClick={() => setSendDocumentsLater(true)}>
                <Stack direction='row' alignItems='center' spacing={1}>
                  <MailOutline color='primary' style={{ height: '1rem', flexShrink: 0 }} />
                  <Typography variant='button' color={theme.palette.text.primary}>
                    I'll email it
                  </Typography>
                </Stack>
              </Button>
            )}
            <Button variant='contained' color='primary' disabled={nextDisabled} onClick={next}>
              <Stack direction='row' alignItems='center' spacing={1}>
                <Typography variant='button' color={theme.palette.primary.contrastText}>
                  Next
                </Typography>
                <ArrowRight size='1rem' color={theme.palette.primary.contrastText} />
              </Stack>
            </Button>
          </Stack>
        </Stack>
      );
    } else {
      footer = <TransactionClarificationCompleteContent loading={loading} error={error || undefined} documentToBeSent={sendDocumentsLater} />;
    }

    let background: string;
    if (error) {
      background = alpha(theme.palette.error.main, 0.15);
    } else if (selected) {
      background = alpha(theme.palette.primary.main, 0.05);
    } else {
      background = theme.palette.background.default;
    }

    return (
      <OutlineContainer
        ref={ref}
        background={background}
        onClick={onSelect}
        style={{
          position: 'relative',
          ...style,
        }}
        onKeyDown={onEnter}
      >
        <OutlineContainerSection
          borderBottom={outlineContainerBorder}
          paddingX={SMALL_HORIZONTAL_SPACING}
          paddingY={SMALL_VERTICAL_SPACING}
          alignItems='start'
        >
          <Stack spacing={0} alignItems='center'>
            <Typography variant='small'>{formatInTimeZone(transaction.date, session?.timeZone || 'UTC', 'MMM').toUpperCase()}</Typography>
            <Typography>{formatInTimeZone(transaction.date, session?.timeZone || 'UTC', 'dd')}</Typography>
          </Stack>

          <Stack spacing={0} flex={1}>
            <Typography variant='h4'>{transaction.name}</Typography>

            {accountDescription}

            <AccountDetailsDialog open={accountDetailsDialogOpen} onClose={() => setAccountDetailsDialogOpen(false)} account={transaction.account} />
          </Stack>

          <Stack spacing={0}>
            <Typography align='right'>{amountFormatter.format(Math.abs(parseFloat(transaction.amount)))}</Typography>
            <Chip
              size='small'
              sx={{
                fontSize: '0.666rem',
              }}
              color={isExpense ? 'error' : 'primary'}
              label={isExpense ? 'EXPENSE' : 'INCOME'}
            />
          </Stack>
        </OutlineContainerSection>

        {content}

        <OutlineContainerSection borderTop={outlineContainerBorder} paddingX={SMALL_HORIZONTAL_SPACING} paddingY={SMALL_VERTICAL_SPACING}>
          {footer}
        </OutlineContainerSection>
      </OutlineContainer>
    );
  }
);

export function TransactionClarificationWidget({ isSuccess }: IWidgetProps) {
  const theme = useTheme();
  const data = useConversationData();
  const outlineContainerBorder = useOutlineContainerBorder();
  const { conversationId, addConversationMessage, scrollLockMutable } = useConversation();

  const [selectedTransaction, setSelectedTransaction] = useState<string | null>(null);
  const [completed, setCompleted] = useState(new Set<string>());

  const initialized = useRef(false);
  useEffect(() => {
    if (data && !initialized.current) {
      initialized.current = true;
      setCompleted(new Set(data.transactionsCompleted));
    }
  }, [data]);

  let totalTransactions = 0;
  let transactionContent: ReactNode;
  if (!data) {
    transactionContent = (
      <TransitionGroup component={null}>
        <Fade timeout={{ enter: 250, exit: 0 }} mountOnEnter unmountOnExit>
          <TransactionPlaceholder />
        </Fade>
        <Fade timeout={{ enter: 250, exit: 0 }} mountOnEnter unmountOnExit>
          <TransactionPlaceholder />
        </Fade>
        <Fade timeout={{ enter: 250, exit: 0 }} mountOnEnter unmountOnExit>
          <TransactionPlaceholder />
        </Fade>
      </TransitionGroup>
    );
  } else {
    const transactionsRequiringDescriptions = new Set(data.transactionsRequiringDescriptions.map((t) => t.id));
    const transactionsRequiringDocuments = new Set(data.transactionsRequiringDocuments.map((t) => t.id));

    let transactionsRequiringClarification = [];
    for (const txRequiringDescription of data.transactionsRequiringDescriptions) {
      transactionsRequiringClarification.push(txRequiringDescription);
    }
    for (const txRequiringDocument of data.transactionsRequiringDocuments) {
      if (!transactionsRequiringDescriptions.has(txRequiringDocument.id)) {
        transactionsRequiringClarification.push(txRequiringDocument);
      }
    }

    totalTransactions = transactionsRequiringClarification.length;

    transactionsRequiringClarification = transactionsRequiringClarification.filter((t) => !completed.has(t.id));

    const complete = async ({
      id,
      descriptionProvided,
      documentProvided,
      awaitingDocumentEmail,
      savedForLater,
    }: {
      id: string;
      descriptionProvided?: string;
      documentProvided?: boolean;
      awaitingDocumentEmail?: boolean;
      savedForLater?: boolean;
    }) => {
      const currentTransactionIdx = transactionsRequiringClarification.findIndex((t) => t.id === id);
      const nextTransactionId =
        transactionsRequiringClarification.length > currentTransactionIdx + 1
          ? transactionsRequiringClarification[currentTransactionIdx + 1].id
          : null;

      setCompleted((existing) => {
        const newSet = new Set(existing);
        newSet.add(id);
        return newSet;
      });

      setSelectedTransaction(nextTransactionId);

      await addConversationMessage({
        conversationId: conversationId!,
        includeMessageInConversation: false,
        message: JSON.stringify({
          id,
          descriptionProvided,
          documentProvided,
          awaitingDocumentEmail,
          savedForLater,
        }),
      });
    };

    const incomplete = (id: string) => {
      setCompleted((existing) => {
        const newSet = new Set(existing);
        newSet.delete(id);
        return newSet;
      });
    };

    transactionContent = (
      <TransitionGroup component={null}>
        {transactionsRequiringClarification.map((t, i) => (
          <DelayCollapse
            key={t.id}
            timeout={250}
            delayMs={{ enter: 250 * i, exit: 0 }}
            mountOnEnter
            unmountOnExit
            onEnter={() => (scrollLockMutable.current = false)}
            onEntered={() => (scrollLockMutable.current = true)}
            onExiting={() => (scrollLockMutable.current = false)}
            onExited={() => (scrollLockMutable.current = true)}
          >
            <TransactionClarification
              selected={selectedTransaction === t.id}
              transaction={t}
              requiresDescription={transactionsRequiringDescriptions.has(t.id)}
              requiresDocument={transactionsRequiringDocuments.has(t.id)}
              onSelect={() => setSelectedTransaction(t.id)}
              onCompleteChange={async (completionDetails) => {
                if (completionDetails.isComplete) {
                  await complete({
                    id: t.id,
                    descriptionProvided: completionDetails.descriptionProvided,
                    documentProvided: completionDetails.documentProvided,
                    awaitingDocumentEmail: completionDetails.awaitingDocumentEmail,
                    savedForLater: completionDetails.savedForLater,
                  });
                } else {
                  incomplete(t.id);
                }
              }}
            />
          </DelayCollapse>
        ))}
      </TransitionGroup>
    );
  }

  return (
    <Fade in={true}>
      <OutlineContainer>
        <OutlineContainerSection
          justifyContent='space-between'
          background={theme.palette.background.default}
          borderBottom={completed.size < totalTransactions ? outlineContainerBorder : undefined}
        >
          <DelayFade
            in={isSuccess === undefined && !!totalTransactions && completed.size < totalTransactions}
            delayMs={{ exit: 500 }}
            timeout={{ exit: 300 }}
            mountOnEnter
            unmountOnExit
          >
            <Typography variant='h3'>Transactions to review</Typography>
          </DelayFade>

          <DelayFade
            in={isSuccess || (!!totalTransactions && completed.size === totalTransactions)}
            delayMs={{ enter: isSuccess ? 0 : 800 }}
            timeout={{ enter: 300 }}
            mountOnEnter
            unmountOnExit
          >
            <Stack direction='row' alignItems='center'>
              <Typography variant='h3' align='center'>
                Transactions reviewed
              </Typography>

              <CheckCircle style={{ color: theme.palette.primary.main }} />
            </Stack>
          </DelayFade>

          <DelayFade
            in={isSuccess === undefined && !!totalTransactions && completed.size < totalTransactions}
            delayMs={{ exit: 500 }}
            timeout={{ exit: 300 }}
            mountOnEnter
            unmountOnExit
          >
            <ProgressBar complete={completed.size} total={totalTransactions} />
          </DelayFade>
        </OutlineContainerSection>

        <Collapse in={completed.size < totalTransactions} timeout={300} mountOnEnter unmountOnExit>
          <OutlineContainerSection direction='column' alignItems='stretch' spacing={SMALL_VERTICAL_SPACING}>
            {transactionContent}
          </OutlineContainerSection>
        </Collapse>
      </OutlineContainer>
    </Fade>
  );
}
